import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '~/services/api';
import { Container, Notifications } from './styles';
import ChatArea from '~/components/MessagesArea';
import Loading from '~/components/Loading';
import { useAuth } from '~/hooks/Auth';

function Messages() {
  const { user } = useAuth();
  const [totalNotifications, settotalNotifications] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);

  const handletotalNotifications = () => {
    setNotifications(notifications + 1);
    settotalNotifications(notifications + notifications + 2);
  };

  useEffect(() => {
    setLoading(true);
    api
      .get(`correspondents/chats/all`, {
        params: {
          type: 'correspondent',
        },
      })
      .then(response => {
        const data = response.data.data.map(chat => {
          return {
            id: chat.id,
            os_id: chat.os_id,
            name: chat.name,
            message: chat.message,
          };
        });
        setChats(data);
        setLoading(false);
      });
  }, [user.id]);

  return (
    <>
      <Container className="xl:container mx-auto py-16">
        <div className="flex flex-wrap items-center justify-between">
          <div className="w-3/12">
            <div className="flex mb-16">
              <h1>Chats</h1>
              <h2
                className={`${
                  totalNotifications > 0 ? 'block' : 'hidden'
                } total-notifications px-4 py-1 ml-4 my-auto`}
              >
                {totalNotifications}
              </h2>
            </div>
            <Notifications>
              <div className="height-notifications overflow-y-auto">
                <button onClick={handletotalNotifications} className="hidden">
                  teste
                </button>
                {chats.map(data => (
                  <>
                    <Link
                      to={`${process.env.PUBLIC_URL}/mensagens/${data.id}`}
                      className="flex justify-between cursor-pointer notifications-hover p-3 rounded-lg w-full"
                    >
                      <div>
                        <h2 className="text-base font-medium mb-2">
                          {data.name}
                        </h2>
                        <h3 className="text-base font-medium mb-2">
                          OS: {data.os_id.toString().padStart(4, '0')}
                        </h3>
                        <p className="text-sm ">{data.message.slice(0, 90)}…</p>
                      </div>

                      <h2
                        className={`${
                          notifications > 0 ? 'block' : 'hidden'
                        } notifications mx-5 my-auto px-2.5 py-1 rounded-full`}
                      >
                        {notifications}
                      </h2>
                    </Link>
                    <hr className="my-5 w-9/12" />
                  </>
                ))}
              </div>
            </Notifications>
          </div>
          <ChatArea />
        </div>
      </Container>
      <Loading active={loading} />
    </>
  );
}

export default Messages;
