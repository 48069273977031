import styled from 'styled-components';
import { Modal as ModalComponent } from '~/components/Modal';

export const Container = styled.div`
  width: calc(100% - 132px);

  .subtitle {
    ::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #ffbe21;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .download-record {
    color: #5c5c5d;
    background: #e0e0e0;
    border-radius: 50px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const Progress = styled.div`
  height: 8px;
  background: #ececec;
  border-radius: 100px;

  ::before {
    content: '${props => props.percent}%';
    position: absolute;
    top: -40px;
    left: calc(${props => props.percent}% - 25px);
    background: #f4f4f4;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
    z-index: 100;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.2s;
  }

  ::after {
    content: '';
    position: absolute;
    background-color: #a1c64d;
    border-radius: 100px;
    width: ${props => props.percent}%;
    height: 8px;
    top: 0;
    left: 0;
  }

  :hover {
    ::before {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Card = styled.button`
  background: #ffffff;
  border: 1px solid ${props => props.borderColor};
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
  border-radius: 20px;

  table {
    border-collapse: collapse;

    th,
    td {
      text-align: left;
    }

    th {
      color: #bdbdbd;
    }

    tr {
      + tr {
        border-top: 1px solid #dadada;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modalContent {
    width: 1130px;
  }

  .input {
    border-bottom: 1px solid #cccccc;
    position: relative;

    .bg-gray {
      background: #e8e4e4;
      border-radius: 10px 10px 0px 0px;
    }

    span:not(.error) {
      color: #414142;
      font-size: 18px;
      font-weight: 600;
    }

    span.error {
      position: absolute;
      bottom: -23px;
    }

    input {
      color: #8a8b8b;
    }
  }

  .box {
    border: 1px solid #cccccc;
    border-radius: 10px;

    .bg-gray {
      background: #e8e4e4;
      border-radius: 10px 10px 0px 0px;

      button {
        background: #c4c4c4;
        color: #444444;
        font-weight: 600;
      }
    }
  }

  button.btn-disapproved,
  button.btn-approved {
    color: #ffffff;
    font-weight: 700;
  }

  button.btn-disapproved {
    background-color: #f6002c;
  }

  button.btn-approved {
    background-color: #079269;
  }
`;
