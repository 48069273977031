import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useValidError } from '~/hooks/ValidError';
import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer, Background } from './styles';
import Input from '~/components/Input';
import Loading from '~/components/Loading';

import logo from '~/assets/logos/logo-login.png';

export default function Login() {
  const formRef = useRef(null);
  const { validError } = useValidError();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async data => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('O endereço de e-mail deve ser um e-mail válido')
            .required('O endereço de e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          validError(error, 'login');
        }
      } finally {
        setLoading(false);
      }
    },
    [signIn, validError],
  );

  return (
    <Container className="flex items-center">
      <div className="w-full h-full">
        <div className="flex flex-row justify-start items-center h-full">
          <AnimationContainer className="w-full md:w-5/12 px-5">
            <div className="items-center my-4 md:pl-5">
              <div className="w-full text-center mb-4">
                <img src={logo} alt="Logo" />
              </div>
              <h1 className="text-2xl text-left text-bold mb-2 mt-9 color-onyx font-semibold">
                Bem-vindo novamente
              </h1>

              <h2 className="text-lg mb-3 text-left text-normal">
                O primeiro passo para você construir a sua casa.
              </h2>

              <Form ref={formRef} onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block mb-1 mt-3 small">
                    E-mail:
                  </label>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-transparent border-bottom-only"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-1 mt-3 small">
                    Senha:
                  </label>
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    className="bg-transparent border-bottom-only"
                  />
                </div>
                <button
                  type="submit"
                  className="block px-5 py-2 button-login mt-5 w-full rounded-full"
                >
                  Entrar
                </button>
              </Form>
            </div>
          </AnimationContainer>
          <Background className="hidden md:block w-7/12 text-center">
            <div className="shade" />
          </Background>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
}
