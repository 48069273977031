import styled, { keyframes } from 'styled-components';

import signInBackgroung from '~/assets/banners/sign-in-background.png';

export const Container = styled.div`
  background-color: #ffffff;
  min-height: 100vh;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Background = styled.div`
  filter: drop-shadow(-8px 0px 14px rgba(0, 0, 0, 0.25));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${signInBackgroung});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  left: 0;

  img {
    filter: brightness(1000%);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  animation: ${appearFromLeft} 1s;

  h2 {
    color: #b8b9ba;
  }

  p {
    color: #b8b9ba;
  }

  .avatars {
    hr {
      width: 33px;
    }

    img {
      width: 40px;
      height: 40px;
    }

    img.opacity-50 {
      opacity: 50%;
    }
  }
`;

export const BoxButton = styled.label`
  background-color: transparent;
  border: 1px solid #cccccc;
  border-radius: 10px;
`;

export const Box = styled.div`
  border: 1px solid #cccccc;
  border-radius: 10px;
  position: relative;

  span {
    position: absolute;
    bottom: -25px;
  }
`;
