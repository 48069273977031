import styled from 'styled-components';

export const Container = styled.div`
  .alert {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #c64d4d;
    top: 5px;
    right: 3px;
  }
`;

export const Notifications = styled.div`
  transition-duration: 0.3s;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  opacity: ${props => (props.opened ? 1 : 0)};
  visibility: ${props => (props.opened ? 'visible' : 'hidden')};
  z-index: 1000;

  h4 {
    color: #414142;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
  }

  > button {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    cursor: default !important;
  }

  > div {
    transition-duration: 0.3s;
    position: relative;
    right: ${props => (props.opened ? 0 : '-1000px')};
    width: 320px;
    height: 100%;
    overflow: auto;

    > button {
      color: #949494;
      font-size: 20px;
    }
  }
`;

export const Notification = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 10px;

  small {
    color: #a8a8a8;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
  }

  div {
    div:last-child {
      width: calc(100% - 50px);
      padding-left: 10px;

      h6 {
        color: #414142;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #b8b9ba;
      }

      a {
        background: #39a887;
        border-radius: 80px;
        border: none;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
      }
    }
  }

  + div {
    margin-top: 20px;
  }
`;

export const Avatar = styled.div`
  width: 37px;
  height: 37px;
  background: #e8f9f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;
