import styled, { css } from 'styled-components';

import dashboard from '~/assets/icons/dashboard.svg';
import dashboardActive from '~/assets/icons/dashboard-active.svg';
import chat from '~/assets/icons/chat-menu.svg';
import chatActive from '~/assets/icons/chat-menu-active.svg';
import addOs from '~/assets/icons/add-os.svg';
import addOsActive from '~/assets/icons/add-os-active.svg';
import logout from '~/assets/icons/logout.svg';
import logoutActive from '~/assets/icons/logout-active.svg';

export const Container = styled.header`
  width: 78px;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 66px;
  }
`;

export const Content = styled.div`
  position: fixed;
  top: 0;
  background: #ffffff;
  z-index: 200;
  width: 100%;

  @media screen and (max-width: 991px) {
    height: 66px;
    left: 0;
  }

  @media screen and (min-width: 992px) {
    width: 78px !important;
  }
`;

export const MenuMobileButton = styled.button`
  width: 30px;
  height: 30px;

  span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #9c9a9a;
    border-radius: 30px;
    transition-duration: 0.3s;

    :nth-child(2) {
      width: 90%;
      margin: 6px 0 6px auto;
    }

    ${props =>
      props.active &&
      css`
        :nth-child(1) {
          transform: rotate(45deg);
        }

        :nth-child(2) {
          opacity: 0;
        }

        :nth-child(3) {
          transform: rotate(-45deg);
          margin-top: -18px;
        }
      `}
  }
`;

export const Menu = styled.div`
  height: 100vh;

  .min-height {
    min-height: 50px;
  }

  .menu-group {
    height: calc(100vh - 100px);
  }

  .menu,
  .logoff {
    a,
    button {
      background: #ffffff;
      color: #999999;
      position: relative;

      div {
        width: 22px;
        height: 22px;
        margin: 0 auto;
        transition-duration: 0.3s;
      }

      .dashboard {
        background-image: url(${dashboard});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .chat {
        background-image: url(${chat});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .add-os {
        background-image: url(${addOs});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .logout {
        background-image: url(${logout});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      small {
        transition-duration: 0.3s;
      }
    }

    a:hover,
    button:hover,
    a.active {
      text-decoration: none;

      .dashboard {
        background-image: url(${dashboardActive});
      }

      .chat {
        background-image: url(${chatActive});
      }

      .add-os {
        background-image: url(${addOsActive});
      }

      .logout {
        background-image: url(${logoutActive});
      }

      small {
        color: #a1c64d;
      }
    }
  }

  @media screen and (max-width: 991px) {
    visibility: ${props => (props.active ? 'visible' : 'hidden')};

    .menu-group {
      width: 100%;
      height: 100vh;
      background: #ffffff;
      transition-duration: 0.3s;
      left: ${props => (props.active ? '0' : '1000%')};
    }

    .menu,
    .logoff {
      a,
      button {
        div {
          margin: 0 10px;
        }

        small {
          position: relative;
          z-index: 0;
          text-align: center;
          opacity: 1;
          width: 60px;
          justify-content: center;
          left: 0 !important;
          width: 100px !important;
          padding: 0 !important;
        }
      }
    }
  }
`;
