import styled from 'styled-components';
import { Form } from '@unform/web';
import ReactChatView from 'react-chatview';

export const ChatArea = styled(Form)`
  border: 1px solid #dedede;
  border-radius: 30px;
  h2 {
    color: #2a2c30;
    font-size: 24px;
    font-weight: 600;
  }

  button {
    background-color: #a1c64d;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
  }

  textarea {
    background-color: transparent;
    resize: none;
    color: #3d3f41;
  }
  .border-user {
    border-bottom: 1px solid #dedede;
  }
  .circle-online {
    background-color: #1ee170;
    height: 9px;
    width: 9px;
  }

  .os-user {
    color: #b8b9ba;
    font-weight: 500;
  }

  .icon svg {
    margin: 0 auto !important;
  }

  .change-msg {
    height: 80vh;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
`;

export const ChatText = styled(ReactChatView)`
  height: 55vh;
  overflow-y: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 5rem;

  .bg-dark-gray {
    color: #fff;
    font-weight: 500;
    border-radius: 10px 10px 0 10px;
    background-color: #8a8a8a;
  }

  .bg-light-gray {
    color: #3d3f41;
    font-weight: 500;
    border-radius: 10px 10px 10px 0;
    background-color: #e4e4e4;
  }

  .date-message {
    font-weight: 500;
    color: #b0b6c3;
    font-size: 10px;
  }

  .tail-dark-gray {
    position: relative;
    top: 8px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: 28px;
  }

  .tail-light-gray {
    position: relative;
    top: 9px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: -30px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }
`;

export const Container = styled.div``;
