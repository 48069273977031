import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function CircleName({ color, name }) {
  return (
    <Container
      color={color}
      className="flex items-center justify-center rounded-full m-1"
    >
      <p className="font-medium text-base">{name}</p>
    </Container>
  );
}

CircleName.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
