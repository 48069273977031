import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { BsImage } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';

import { Container } from './styles';

export default function ButtonAttachment({
  title,
  icon,
  iconColor,
  className,
  onClick,
  onDelete,
  approved,
  observation,
  statusColor,
  downloadable,
  fileUrl,
  showDeleteButton,
}) {
  const handleClick = useCallback(() => {
    if (downloadable) {
      window.open(fileUrl, '_blank').focus();
    } else {
      onClick();
    }
  }, [downloadable, fileUrl, onClick]);

  const handleClickDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  return (
    <Container
      className="flex flex-wrap items-center"
      approved={approved}
      statusColor={statusColor}
    >
      <div
        className={`w-4/12 flex items-center justify-between text-left btn-group ${className}`}
      >
        <button
          type="button"
          onClick={handleClick}
          className={!showDeleteButton ? 'w-full' : 'w-10/12'}
        >
          <div className="flex items-start">
            {icon === 'image' ? (
              <BsImage size={30} color={iconColor} />
            ) : (
              <AiOutlinePaperClip size={30} color={iconColor} />
            )}
            <h3 className="ml-4">{title}</h3>
          </div>
        </button>
        {showDeleteButton && (
          <button type="button" onClick={handleClickDelete} className="w-2/12">
            <MdDelete size={24} color="#FF005C" />
          </button>
        )}
      </div>
      {approved.length > 0 && (
        <div className="w-8/12 px-4">
          <div className="alert-box">
            <p>
              {approved === 'Aprovado' ? (
                'Documento aprovado'
              ) : (
                <>{observation || 'Necessita correção'}</>
              )}
            </p>
          </div>
        </div>
      )}
    </Container>
  );
}

ButtonAttachment.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  approved: PropTypes.string,
  observation: PropTypes.string,
  statusColor: PropTypes.string,
  downloadable: PropTypes.bool,
  fileUrl: PropTypes.string,
  showDeleteButton: PropTypes.bool,
};

ButtonAttachment.defaultProps = {
  iconColor: '#E6E7E8',
  className: '',
  approved: '',
  observation: '',
  statusColor: '#00000000',
  onClick: () => {},
  onDelete: () => {},
  downloadable: false,
  fileUrl: '',
  showDeleteButton: false,
};
