import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border: 3px solid ${props => props.color};
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  h3 {
    color: #333333;
  }

  p {
    color: #666666;
  }

  a,
  button {
    background: #e0e0e0;
    border-radius: 50px;
    + a,
    + button {
      margin-left: 10px;
    }
  }
`;
