import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import { Form } from '@unform/web';

import { useValidError } from '~/hooks/ValidError';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container, Progress, Card, Modal } from './styles';
import Header from '~/components/HeaderDashboard';
import MiniProfile from '~/components/MiniProfile';
import Status from '~/components/Status';
import Loading from '~/components/Loading';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputDate from '~/components/InputDate';

export default function Measurements() {
  const { validError } = useValidError();
  const params = useParams();
  const history = useHistory();
  const formRef = useRef(null);
  const [order, setOrder] = useState({});
  const [measurements, setMeasurements] = useState([]);
  const [measurement, setMeasurement] = useState({});
  const [percent, setPercent] = useState(0);
  const [showCheckMeasurement, setShowCheckMeasurement] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(`correspondents/oss/${params.slug}`)
      .then(async response => {
        const data = {
          id: response.data.id,
          step: response.data.status.step.ordem,
        };

        const responseMeasurement = await api.get(
          `correspondents/oss/measurements/${params.slug}`,
        );

        if (responseMeasurement.data) {
          const dataMeasurements = responseMeasurement.data.map(
            measurementData => {
              let borderColor = '#00000000';
              if (measurementData.resultado_id === 1) {
                borderColor = '#A1C64D';
              } else if (measurementData.resultado_id === 2) {
                borderColor = '#FF3C21';
              }

              const date = [
                measurementData.dt_solicitacao
                  ? format(
                      parseISO(measurementData.dt_solicitacao),
                      'dd/MM/yyyy',
                    )
                  : '-',
                measurementData.dt_aprovacao
                  ? format(parseISO(measurementData.dt_aprovacao), 'dd/MM/yyyy')
                  : '-',
              ];

              const percentData = [
                measurementData.porcentagem_estimada &&
                measurementData.porcentagem_estimada >= 0
                  ? measurementData.porcentagem_estimada
                  : '-',
                measurementData.porcentagem_aprovada &&
                measurementData.porcentagem_aprovada >= 0
                  ? measurementData.porcentagem_aprovada
                  : '-',
              ];

              const value = [
                '-',
                measurementData.valor_aprovado
                  ? `R$${formatPrice(measurementData.valor_aprovado)}`
                  : '-',
              ];

              return {
                id: measurementData.id,
                borderColor,
                name: `Medição ${measurementData.numero}`,
                date,
                percent: percentData,
                value,
                file_url: measurementData.arquivo
                  ? measurementData.arquivo.file_url
                  : '',
              };
            },
          );

          const percentTotal = responseMeasurement.data.reduce(
            (acumulador, current) => {
              const sumValue =
                current.resultado_id === 1 && current.porcentagem_aprovada
                  ? current.porcentagem_aprovada
                  : 0;

              return acumulador + sumValue;
            },
            0,
          );

          setPercent(percentTotal);
          setMeasurements(dataMeasurements);
        }

        setOrder(data);
      })
      .catch(error => {
        validError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.slug, validError]);

  useEffect(() => {
    if (percent === 100) {
      const formData = {
        status_id: 25,
      };

      api.put(`correspondents/oss/${params.slug}`, formData);
    } else {
      const formData = {
        status_id: 23,
      };

      api.put(`correspondents/oss/${params.slug}`, formData);
    }
  }, [params.slug, percent]);

  const handleClose = useCallback(() => {
    setShowCheckMeasurement(false);
    setMeasurement({});
  }, []);

  const handleShow = useCallback(() => {
    setShowCheckMeasurement(true);
  }, []);

  const handleClick = useCallback(
    measurementData => {
      const datePart =
        measurementData.date[1] !== '-'
          ? measurementData.date[1].split('/')
          : '';
      let price = measurementData.value[1].replace('.', '');
      price = price.replace(',', '.');
      price = price.replace('R$', '');
      const counter =
        measurementData.borderColor === '#A1C64D'
          ? measurementData.percent[1]
          : 0;

      const data = {
        id: measurementData.id,
        date:
          datePart.length > 0
            ? new Date(datePart[2], datePart[1] - 1, datePart[0])
            : undefined,
        percent:
          measurementData.percent[1] !== '-' ? measurementData.percent[1] : '',
        price: measurementData.value[1] !== '-' ? price : '',
        file_url: measurementData.file_url,
        updated: measurementData.borderColor === '#A1C64D',
        maxPercentAvailable: 100 + parseInt(counter, 10) - percent,
      };
      setMeasurement(data);
      handleShow();
    },
    [handleShow, percent],
  );

  const updatePercent = useCallback(value => {
    setPercent(state => state + value);
  }, []);

  const handleSubmit = useCallback(
    async data => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          date: Yup.string().required('A data da verificação é obrigatória.'),
          percent: Yup.string().required(
            'A porcentagem calculada da obra é obrigatória.',
          ),
          price: Yup.string().required('O valor é obrigatória.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const dateParts = data.date.split('/');
        const price = data.price.split(',')[1]
          ? data.price.replaceAll(/\./g, '')
          : data.price;

        const formData = {
          result: 1,
          date: new Date(dateParts[2], dateParts[1] - 1, dateParts[0]),
          percent: data.percent,
          price: price.replace(',', '.'),
        };

        const response = await api.patch(
          `correspondents/oss/measurements/${measurement.id}`,
          formData,
        );

        if (response.data) {
          Swal.fire(
            'Tudo certo.',
            'Verficiação salva com sucesso!',
            'success',
          ).then(() => {
            setMeasurements(state => {
              const newMeasurements = state.slice();
              const measurementSelected = state.find(
                measurementData => measurementData.id === measurement.id,
              );
              const measurementIndex = state.findIndex(
                measurementData => measurementData.id === measurement.id,
              );

              if (measurementSelected) {
                measurementSelected.borderColor = '#A1C64D';
                measurementSelected.date[1] = data.date;
                measurementSelected.percent[1] = data.percent;
                measurementSelected.value[1] = `R$${formatPrice(
                  formData.price,
                )}`;
                newMeasurements[measurementIndex] = measurementSelected;
              }

              return newMeasurements;
            });
            if (measurement.updated) {
              updatePercent(parseInt(data.percent, 10) - measurement.percent);
            } else {
              updatePercent(parseInt(data.percent, 10));
            }
            setMeasurement({});
            handleClose();
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          validError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      handleClose,
      measurement.id,
      measurement.percent,
      measurement.updated,
      updatePercent,
      validError,
    ],
  );

  const handleDisapproved = useCallback(async () => {
    setLoading(true);
    try {
      const formData = {
        result: 2,
        date: '',
        percent: '',
        price: '',
      };

      const response = await api.patch(
        `correspondents/oss/measurements/${measurement.id}`,
        formData,
      );

      if (response.data) {
        Swal.fire(
          'Tudo certo.',
          'Verficiação salva com sucesso!',
          'success',
        ).then(() => {
          updatePercent(-measurement.percent);
          setMeasurements(state => {
            const newMeasurements = state.slice();
            const measurementSelected = state.find(
              measurementData => measurementData.id === measurement.id,
            );
            const measurementIndex = state.findIndex(
              measurementData => measurementData.id === measurement.id,
            );

            if (measurementSelected) {
              measurementSelected.borderColor = '#FF3C21';
              measurementSelected.date[1] = '-';
              measurementSelected.percent[1] = '-';
              measurementSelected.value[1] = '-';
              newMeasurements[measurementIndex] = measurementSelected;
            }

            return newMeasurements;
          });
          setMeasurement({});
          handleClose();
        });
      }
    } catch (error) {
      validError(error);
    } finally {
      setLoading(false);
    }
  }, [
    handleClose,
    measurement.id,
    measurement.percent,
    updatePercent,
    validError,
  ]);

  return (
    <div className="lg:flex">
      <Header slug={params.slug} />
      {Object.keys(order).length > 0 && (
        <Container className="pb-5">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center justify-between pt-4 mb-10">
              <div className="w-full md:w-9/12 lg:w-6/12">
                <div className="flex items-center px-5">
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="border-0 bg-transparent mr-3"
                  >
                    <IoIosArrowBack size={25} color="#CCCCCC" />
                  </button>
                  <div>
                    <h1 className="text-2xl color-onyx font-semibold mx-8">
                      Medições
                    </h1>
                    <small className="font-medium mx-8 subtitle">
                      Pendentes Construtor
                    </small>
                  </div>
                </div>
              </div>
              <div className="hidden md:block pl-6 pt-4 md:w-3/12">
                <div className="flex justify-end items-center">
                  <MiniProfile />
                </div>
              </div>
            </div>

            <div className="flex flex-row flex-wrap px-4 md:px-8 mb-5 md:mb-10">
              <Status step={order.step} />
            </div>

            <div className="flex flex-col justify-center items-center px-4 md:px-8 mb-5 md:mb-10">
              <h3 className="mb-4 text-xl color-onyx font-bold">
                Progresso aprovado:
              </h3>
              <div className="flex items-center w-full md:w-9/12 lg:w-6/12">
                <p className="text-lg color-onyx font-medium">0%</p>
                <Progress percent={percent} className="w-full relative mx-3" />
                <p className="text-lg color-onyx font-medium">100%</p>
              </div>
            </div>

            <div className="flex flex-wrap px-4 md:px-8 mb-5 md:mb-10">
              {measurements.map(measurementData => (
                <div
                  key={measurementData.id}
                  className="w-full md:w-6/12 lg:w-4/12 p-3 md:p-6"
                >
                  <Card
                    type="button"
                    borderColor={measurementData.borderColor}
                    className="w-full p-3 text-left"
                    onClick={() => handleClick(measurementData)}
                  >
                    <div className="px-4">
                      <h3 className="color-android-green font-bold text-lg mb-2">
                        {measurementData.name}
                      </h3>
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>
                              <small>Solicitação</small>
                            </th>
                            <th>
                              <small>Aprovado</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-2 text-lg font-medium">
                              {measurementData.date[0]}
                            </td>
                            <td className="py-2 text-lg font-medium">
                              {measurementData.date[1]}
                            </td>
                          </tr>
                          <tr>
                            <td className="py-2 text-lg font-medium">
                              {measurementData.percent[0]}
                              {measurementData.percent[0] === '-'
                                ? ''
                                : '% medição'}
                            </td>
                            <td className="py-2 text-lg font-medium">
                              {measurementData.percent[1]}
                              {measurementData.percent[1] === '-'
                                ? ''
                                : '% medição'}
                            </td>
                          </tr>
                          <tr>
                            <td className="py-2 text-lg font-medium">
                              {measurementData.value[0]}
                            </td>
                            <td className="py-2 text-lg font-medium">
                              {measurementData.value[1]}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </Container>
      )}
      <Modal show={showCheckMeasurement} onHide={handleClose}>
        {Object.keys(measurement).length > 0 && (
          <Form ref={formRef} onSubmit={handleSubmit} initialData={measurement}>
            <Modal.Header className="mb-8">
              <h4 className="text-xl color-onyx font-semibold mb-6 lg:mb-0">
                Checar medição
              </h4>
              <div className="md:flex flex-wrap justify-center lg:justify-start mt-2">
                <p>
                  Lorem ipsum porta ac pretium iaculis bibendum id, egestas
                  condimentum hac urna cubilia curabitur
                </p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="flex flex-wrap justify-center items-center">
                <div className="w-full md:w-8/12 lg:w-6/12 my-4 lg:pr-4">
                  <div className="md:px-8">
                    <p className="text-lg md:mb-2">Data da verificação:</p>
                    <div className="input mb-4 lg:mb-6">
                      <div className="flex items-center">
                        <InputDate name="date" value={measurement.date} />
                      </div>
                    </div>
                  </div>
                  <div className="md:px-8">
                    <p className="text-lg md:mb-2">
                      Porcentagem calculada da obra:
                    </p>
                    <div className="input mb-4 lg:mb-6">
                      <div className="flex items-center">
                        <span>%</span>
                        <Input
                          type="number"
                          name="percent"
                          className="border-none"
                          min={0}
                          max={measurement.maxPercentAvailable}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:px-8">
                    <p className="text-lg md:mb-2">Valor:</p>
                    <div className="input mb-4 lg:mb-6">
                      <div className="flex items-center">
                        <span>R$</span>{' '}
                        <InputMask
                          kind="money"
                          options={{
                            unit: '',
                            delimiter: '.',
                            separator: ',',
                          }}
                          name="price"
                          className="border-none"
                          value={measurement.price}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-8/12 lg:w-6/12 my-4 lg:pl-4">
                  <div className="box">
                    <div className="flex justify-end bg-gray p-3">
                      <button type="button" className="py-2 px-4 rounded-full">
                        Baixar arquivo
                      </button>
                    </div>
                    <div className="p-3 md:p-10">
                      <img
                        src={measurement.file_url}
                        alt="Medição"
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="flex flex-wrap justify-end">
                <div className="w-full md:w-6/12 flex flex-wrap md:flex-nowrap justify-end">
                  <button
                    type="button"
                    onClick={handleDisapproved}
                    className="mt-2 md:mt-5 w-full md:mr-1 py-2 rounded-full btn-disapproved order-2 md:order-1"
                  >
                    Reprovar medição
                  </button>
                  <button
                    type="submit"
                    className="mt-2 md:mt-5 w-full md:ml-1 py-2 rounded-full btn-approved order-1 md:order-2"
                  >
                    Aprovar medição
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
      <Loading active={loading} />
    </div>
  );
}
