import React, { createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import { useAuth } from './Auth';

export const ValidErrorContext = createContext({
  validError: () => {},
});

export const ValidErrorProvider = ({ children }) => {
  const { signOut } = useAuth();
  const validError = useCallback(
    (error, page) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === 'Token has expired'
      ) {
        signOut();
      } else if (page && page === 'login') {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro ao fazer login, verifique suas credenciais',
          'error',
        );
      } else {
        Swal.fire('Opss...', 'Ocorreu um erro, tente novamente..', 'error');
      }
    },
    [signOut],
  );

  return (
    <ValidErrorContext.Provider value={{ validError }}>
      {children}
    </ValidErrorContext.Provider>
  );
};

export function useValidError() {
  const context = useContext(ValidErrorContext);

  if (!context) {
    throw new Error('useValidError must be used within an ValidErrorProvider');
  }

  return context;
}

ValidErrorProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
