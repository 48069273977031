import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { formatPrice } from '~/utils/format';

export const CartContext = createContext({
  products: {},
  subtotal: formatPrice(0),
  quantity: 0,
  addToCart: () => {},
  removeFromCart: () => {},
});

export function CartProvider({ children }) {
  const [data, setData] = useState([]);
  const [subtotal, setSubtotal] = useState(formatPrice(0));
  const [quantity, setQuantity] = useState(0);

  const subTotalCalculator = useCallback(products => {
    const subTotal = products.reduce((acumulador, current) => {
      return (
        acumulador +
        parseFloat(current.price.replaceAll('.', '').replace(',', '.'))
      );
    }, 0);

    setSubtotal(formatPrice(subTotal));
  }, []);

  useEffect(() => {
    subTotalCalculator(data);
    setQuantity(data.length);
  }, [data, subTotalCalculator]);

  const addToCart = useCallback(
    product => {
      const productFound = data.find(
        productData => productData.id === product.id,
      );
      if (!productFound) {
        setData(state => [...state, product]);
      }
    },
    [data],
  );

  const removeFromCart = useCallback(
    product_id => {
      const newData = data.filter(product => product.id !== product_id);
      subTotalCalculator(newData);
      setData(newData);
    },
    [data, subTotalCalculator],
  );

  return (
    <CartContext.Provider
      value={{ products: data, subtotal, quantity, addToCart, removeFromCart }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within an CartProvider');
  }

  return context;
}

CartProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
