import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '~/hooks/Auth';

const AutoLogin = () => {
  const { autoSignIn } = useAuth();
  const params = useParams();

  useEffect(() => {
    autoSignIn(params.hash);
  }, [autoSignIn, params.hash]);

  return <div />;
};

export default AutoLogin;
