import React from 'react';

import { Container } from './styles';
import Textarea from '../Textarea';

export default function InputNote() {
  return (
    <Container className="w-full">
      <p className="text-left text-md mb-2">
        Caso queira, deixe alguma observação:
      </p>
      <Textarea name="note" className="box py-5 px-8 md:px-12 lg:mb-16" />
    </Container>
  );
}
