/* eslint-disable no-new */

import logo from '~/assets/logos/logo.png';

async function pushNotification(message) {
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  } else if (Notification.permission === 'default') {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      new Notification('MCF Bank', {
        body: message,
        icon: logo,
      });
    }
  } else if (Notification.permission === 'granted') {
    new Notification('MCF Bank', {
      body: message,
      icon: logo,
    });
  }
}

export default pushNotification;
