import React, { useCallback } from 'react';
import DropzoneComponent from 'react-dropzone';
import PropTypes from 'prop-types';

import { Container, Placeholder, FilesList } from './styles';

import documents from '~/assets/defaults/documents-vector.svg';
import deleteIcon from '~/assets/icons/delete.svg';

export default function Dropzone({ data, onDrop, onRemove }) {
  const handleDrop = useCallback(
    acceptedFiles => {
      onDrop(acceptedFiles);
    },
    [onDrop],
  );

  const handleRemove = useCallback(
    index => {
      onRemove(index);
    },
    [onRemove],
  );

  return (
    <Container className="flex flex-wrap w-full">
      <div className="w-8/12 pr-3">
        <DropzoneComponent
          onDrop={handleDrop}
          accept="image/*,application/pdf,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Placeholder className="flex flex-col justify-center items-center py-20 md:mb-10">
                  <img
                    src={documents}
                    alt="documents vector"
                    className="mb-5"
                  />
                  <p className="text-center color-onyx font-medium">
                    Suba seu arquivo aqui ou <br />
                    <span className="color-android-green font-bold underline">
                      Procurar arquivo
                    </span>
                  </p>
                </Placeholder>
              </div>
            </section>
          )}
        </DropzoneComponent>
      </div>
      <div className="w-4/12 pl-3">
        <FilesList>
          <h4 className="text-xl color-onyx font-semibold mb-3 text-left">
            Lista de arquivos
          </h4>
          <div className="pr-2">
            <ul>
              {data.map((file, index) => (
                <li className="flex justify-between py-2">
                  <span>{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleRemove(index)}
                    className="bg-transparent"
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </FilesList>
      </div>
    </Container>
  );
}

Dropzone.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.any,
  onDrop: PropTypes.func,
  onRemove: PropTypes.func,
};

Dropzone.defaultProps = {
  onDrop: () => {},
  onRemove: () => {},
};
