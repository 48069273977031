import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import { AiOutlineClose } from 'react-icons/ai';

import api from '~/services/api';

import { TableContent, Pagination, Search, Modal } from './styles';
import Select from '~/components/Select';
import InputDate from '~/components/InputDate';
import Loading from '~/components/Loading';

import search from '~/assets/icons/search.svg';

export default function Table({
  title,
  data,
  columns,
  searchable,
  onSearch,
  date,
  exportable,
  pagination,
  onRowClicked,
  onClickPendency,
  slug,
  totalData,
  selectedPage,
  onChangePage,
  fromData,
  toData,
  onSubmitFilters,
}) {
  const [firstButton, setFirstButton] = useState(2);
  const [centerButton, setCenterButton] = useState(3);
  const [lastButton, setLastButton] = useState(4);
  const [pendencySelected, setPendencySelected] = useState('all');
  const [finalities, setFinalities] = useState([]);
  const [status, setStatus] = useState([]);
  const [states, setStates] = useState([]);
  const [statesSelected, setStatesSelected] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get('correspondents/finalities')
      .then(async response => {
        const finalitiesData = response.data.map(finality => ({
          id: finality.id,
          value: finality.finalidade,
          selected: false,
        }));

        finalitiesData.unshift({
          id: 0,
          value: 'Todas',
          selected: true,
        });

        const responseStates = await api.get('correspondents/states');

        const statesData = responseStates.data.map(state => ({
          id: state.id,
          value: state.sigla,
          selected: false,
        }));

        const responseStatus = await api.get('correspondents/status');

        const statusData = responseStatus.data.map(dataStatus => ({
          id: dataStatus.id,
          value: dataStatus.status,
          selected: false,
        }));

        statusData.unshift({
          id: 0,
          value: 'Todos',
          selected: true,
        });

        setFinalities(finalitiesData);
        setStates(statesData);
        setStatus(statusData);
      })
      .finally(() => setLoading(false));
  }, []);

  const totalPages = useMemo(() => {
    const pages = Math.ceil(totalData / 10);
    return pages;
  }, [totalData]);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleChangePage = useCallback(
    page => {
      let pedency = 'all';
      if (pendencySelected === 'builder') {
        pedency = 1;
      } else if (pendencySelected === 'correspondent') {
        pedency = 2;
      }

      onChangePage(page, pedency);
      if (page >= 3) {
        if (page <= totalPages - 2) {
          setFirstButton(page - 1);
        } else if (page === totalPages) {
          if (totalPages === 3) {
            setFirstButton(page - 1);
          } else if (totalPages === 4) {
            setFirstButton(page - 2);
          } else {
            setFirstButton(page - 3);
          }
        } else if (totalPages === 4) {
          setFirstButton(page - 1);
        } else {
          setFirstButton(page - 2);
        }
      } else if (page === 1) {
        setFirstButton(page + 1);
      } else {
        setFirstButton(page);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setCenterButton(page);
        } else if (page === totalPages) {
          if (totalPages === 4) {
            setCenterButton(page - 1);
          } else {
            setCenterButton(page - 2);
          }
        } else if (totalPages === 4) {
          setCenterButton(page);
        } else {
          setCenterButton(page - 1);
        }
      } else if (page === 1) {
        setCenterButton(page + 2);
      } else {
        setCenterButton(page + 1);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setLastButton(page + 1);
        } else if (page === totalPages) {
          setLastButton(page - 1);
        } else {
          setLastButton(page);
        }
      } else if (page === 1) {
        setLastButton(page + 3);
      } else {
        setLastButton(page + 2);
      }
    },
    [onChangePage, pendencySelected, totalPages],
  );

  const handleClickPendency = useCallback(
    value => {
      if (pendencySelected === value) {
        setPendencySelected('all');
        onClickPendency('all');
      } else {
        setPendencySelected(value);
        onClickPendency(value);
      }
    },
    [onClickPendency, pendencySelected],
  );

  const handleSearch = useCallback(
    e => {
      let pedency = 'all';
      if (pendencySelected === 'builder') {
        pedency = 1;
      } else if (pendencySelected === 'correspondent') {
        pedency = 2;
      }

      onSearch(e.target.value, pedency);
    },
    [onSearch, pendencySelected],
  );

  const orderAlphabetic = useCallback(arr => {
    arr.sort((a, b) => {
      const textA = a.value.toUpperCase();
      const textB = b.value.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });

    return arr;
  }, []);

  const handleSelected = useCallback(
    stateSelected => {
      let newStates = states.filter(state => state.id !== stateSelected.id);
      newStates = orderAlphabetic(newStates);

      let newStatesSelected = statesSelected.slice();
      newStatesSelected.push(stateSelected);
      newStatesSelected = orderAlphabetic(newStatesSelected);

      setStatesSelected(newStatesSelected);
      setStates(newStates);
    },
    [orderAlphabetic, states, statesSelected],
  );

  const handleRemoveSelected = useCallback(
    stateSelected => {
      let newStatesSelected = statesSelected.filter(
        state => state.id !== stateSelected.id,
      );
      newStatesSelected = orderAlphabetic(newStatesSelected);

      let newStates = states.slice();
      newStates.push(stateSelected);
      newStates = orderAlphabetic(newStates);

      setStates(newStates);
      setStatesSelected(newStatesSelected);
    },
    [orderAlphabetic, states, statesSelected],
  );

  const handleSubmit = useCallback(
    formData => {
      formData.states = statesSelected;

      if (formData.initial_date) {
        const [initialDay, initialMonth, initialYear] =
          formData.initial_date.split('/');
        formData.initial_date = new Date(
          initialYear,
          initialMonth - 1,
          initialDay,
          0,
          0,
          0,
          0,
        );
      }

      if (formData.end_date) {
        const [endDay, endMonth, endYear] = formData.end_date.split('/');
        formData.end_date = new Date(
          endYear,
          endMonth - 1,
          endDay,
          23,
          59,
          59,
          99,
        );
      }

      if (onSubmitFilters) {
        onSubmitFilters(formData);
      }
      handleClose();
    },
    [handleClose, onSubmitFilters, statesSelected],
  );

  return (
    <>
      <TableContent
        className={`relative overflow-auto px-4 md:px-8 py-4 ${
          data.length > 0 ? 'mb-3' : ''
        }`}
        showHeader={!!(title || searchable || date || exportable)}
        rowIsClickable={!!onRowClicked}
      >
        <div className="flex flex-wrap items-end w-full justify-between menu-table">
          {title && (
            <>
              <div className="w-full mb-5">
                <h2 className="text-xl color-onyx font-bold text-center lg:text-left">
                  {title}
                </h2>
              </div>
            </>
          )}
          {searchable && (
            <div className="w-full flex flex-wrap">
              <div className="flex items-center w-full lg:w-6/12 mb-4">
                <Search className="flex px-2 w-8/12">
                  <input
                    className="w-full"
                    placeholder="Procurar"
                    onChange={handleSearch}
                  />
                  <img src={search} alt="Search" className="mx-2" />
                </Search>
                <button
                  type="button"
                  className="color-android-green font-semibold bg-transparent block w-4/12"
                  onClick={handleShow}
                >
                  + Filtrar por outros
                </button>
              </div>
            </div>
          )}
          <div className="w-full xl:w-6/12 order-1 xl:order-0">
            <div className="flex flex-col md:flex-row flex-wrap justify-center xl:justify-start legend md:mb-3 xl:mb-0 ">
              <button
                type="button"
                className={`border-none bg-transparent mb-1 md:mb-0 md:mr-5 ${
                  pendencySelected === 'builder' ? 'active' : ''
                }`}
                onClick={() => handleClickPendency('builder')}
              >
                Pendentes Construtor
              </button>
              <button
                type="button"
                className={`border-none bg-transparent mb-4 md:mb-0 ${
                  pendencySelected === 'correspondent' ? 'active' : ''
                }`}
                onClick={() => handleClickPendency('correspondent')}
              >
                Pendentes Correspondente
              </button>
            </div>
          </div>
          <div className="w-full xl:w-6/12 order-0 xl:order-1 mb-5 flex flex-wrap justify-center xl:justify-end">
            <Link
              to={`${process.env.PUBLIC_URL}/home/com-correspondente`}
              className={`flex items-center text-white border-none px-12 py-1 rounded-full text-lg mb-2 md:mb-0 md:mr-2 ${
                slug === 'com-correspondente' ? 'bg-onyx' : 'bg-onyx-outline'
              }`}
            >
              Com correspondente
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/home/sem-correspondente`}
              className={`flex items-center text-white border-none px-12 py-1 rounded-full text-lg ${
                slug === 'sem-correspondente' ? 'bg-onyx' : 'bg-onyx-outline'
              }`}
            >
              Sem correspondente
            </Link>
          </div>
          <div className="w-full mb-5 divisor order-2" />
        </div>
        <div className="tableData crowdfunding-table">
          <DataTable
            data={data}
            columns={columns}
            onRowClicked={onRowClicked}
            dense
            noDataComponent={
              <div className="flex text-center justify-center items-center">
                <p className="text-lg mb-0">Não há registros por aqui</p>
              </div>
            }
          />
        </div>
        {data.length > 0 && pagination && (
          <Pagination className="flex flex-wrap pb-1 pt-3 px-3 items-center justify-between menu-table">
            <div className="w-full text-center md:text-left md:w-6/12 px-4 mb-5 md:mb-0">
              <p>
                Showing {fromData}-{toData} from {totalData}
              </p>
            </div>
            <div className="w-full md:w-6/12">
              <div className="flex w-full justify-center md:justify-end">
                <button
                  type="button"
                  className="border-0 bg-transparent mx-1 px-1"
                  onClick={() => handleChangePage(1)}
                >
                  First
                </button>
                <button
                  type="button"
                  className={`border-0 bg-transparent mx-1 px-1 ${
                    selectedPage === 1 ? 'active' : ''
                  }`}
                  onClick={() => handleChangePage(1)}
                >
                  01
                </button>
                {totalPages > 1 && (
                  <>
                    {totalPages > 5 && (
                      <>
                        {selectedPage > 3 && (
                          <span className="border-0 bg-transparent mx-1 px-1">
                            ...
                          </span>
                        )}
                      </>
                    )}
                    {totalPages > 2 && (
                      <button
                        type="button"
                        className={`border-0 bg-transparent mx-1 px-1 ${
                          selectedPage === firstButton ? 'active' : ''
                        }`}
                        onClick={() => handleChangePage(firstButton)}
                      >
                        {`0${firstButton}`.slice(-2)}
                      </button>
                    )}
                    {totalPages > 3 && (
                      <button
                        type="button"
                        className={`border-0 bg-transparent mx-1 px-1 ${
                          selectedPage === centerButton ? 'active' : ''
                        }`}
                        onClick={() => handleChangePage(centerButton)}
                      >
                        {`0${centerButton}`.slice(-2)}
                      </button>
                    )}
                    {totalPages > 4 && (
                      <button
                        type="button"
                        className={`border-0 bg-transparent mx-1 px-1 ${
                          selectedPage === lastButton ? 'active' : ''
                        }`}
                        onClick={() => handleChangePage(lastButton)}
                      >
                        {`0${lastButton}`.slice(-2)}
                      </button>
                    )}
                    {totalPages > 5 && (
                      <>
                        {selectedPage < totalPages - 2 && (
                          <span className="border-0 bg-transparent mx-1 px-1">
                            ...
                          </span>
                        )}
                      </>
                    )}
                  </>
                )}
                {totalPages > 1 && (
                  <button
                    type="button"
                    className={`border-0 bg-transparent mx-1 px-1 ${
                      selectedPage === totalPages ? 'active' : ''
                    }`}
                    onClick={() => handleChangePage(totalPages)}
                  >
                    {`0${totalPages}`.slice(-2)}
                  </button>
                )}
                <button
                  type="button"
                  className="border-0 bg-transparent mx-1 px-1"
                  onClick={() => handleChangePage(totalPages)}
                >
                  Last
                </button>
              </div>
            </div>
          </Pagination>
        )}
      </TableContent>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="mb-8">
            <div className="w-full">
              <h3 className="text-2xl color-onyx font-semibold mb-3 text-left">
                Filtrar por outros
              </h3>
              <p className="text-left">
                Caso deseje filtrar por categorias mais especificas, você pode
                escolher por aqui
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-wrap justify-start px-0 md:px-8">
              <div className="w-full md:px-5 mb-6">
                <label>Finalidade</label>
                <Select
                  name="finality"
                  options={finalities}
                  className="p-3"
                  placeholder="Selecione"
                />
              </div>
              <div className="w-full md:w-6/12 md:px-5 mb-4">
                <div className="w-full mb-6">
                  <label>Datas</label>
                  <div className="flex items-center justify-between">
                    <div className="w-6/12 pr-3">
                      <InputDate
                        name="initial_date"
                        className="input pt-1 pb-3"
                      />
                    </div>
                    <div className="w-6/12 pl-3">
                      <InputDate name="end_date" className="input pt-1 pb-3" />
                    </div>
                  </div>
                </div>
                <div className="w-full my-4">
                  <label>Status</label>
                  <Select
                    name="status"
                    options={status}
                    placeholder="Selecione"
                    className="mb-4 p-3"
                  />
                </div>
              </div>
              <div className="w-full md:w-6/12 md:px-5 mb-4">
                <label>Estado da OS</label>
                <Select
                  name="states"
                  options={states}
                  placeholder="Selecione"
                  className="mb-4 p-3"
                  onChange={handleSelected}
                  cleanField
                />
                <div className="flex flex-wrap">
                  {statesSelected.map(state => (
                    <div
                      key={state.id}
                      className="flex items-center justify-center state-card mb-2"
                    >
                      <span className="block mr-2">{state.value}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveSelected(state)}
                      >
                        <AiOutlineClose size={10} color="#9f9f9f" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex flex-wrap justify-end">
              <div className="w-full md:w-6/12 flex flex-wrap md:flex-nowrap justify-end">
                <button
                  type="button"
                  onClick={handleClose}
                  className="mt-2 md:mt-5 w-full md:mr-1 py-2 rounded-full btn-cancel order-2 md:order-1"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="mt-2 md:mt-5 w-full md:ml-1 py-2 rounded-full btn-submit order-1 md:order-2"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading active={loading} />
    </>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  searchable: PropTypes.bool,
  onSearch: PropTypes.func,
  date: PropTypes.bool,
  exportable: PropTypes.bool,
  pagination: PropTypes.bool,
  onRowClicked: PropTypes.func,
  onClickPendency: PropTypes.func,
  slug: PropTypes.string,
  totalData: PropTypes.number,
  selectedPage: PropTypes.number,
  onChangePage: PropTypes.func,
  fromData: PropTypes.number,
  toData: PropTypes.number,
  onSubmitFilters: PropTypes.func,
};

Table.defaultProps = {
  title: '',
  searchable: false,
  onSearch: () => {},
  date: false,
  exportable: false,
  pagination: false,
  onRowClicked: () => {},
  onClickPendency: () => {},
  slug: '',
  totalData: 0,
  selectedPage: 0,
  onChangePage: () => {},
  fromData: 0,
  toData: 0,
  onSubmitFilters: () => {},
};
