/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import Swal from 'sweetalert2';

import { useValidError } from '~/hooks/ValidError';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import { Container, Banner, Img, SeeMore, Options } from './styles';
import MiniProfile from '~/components/MiniProfile';
import Table from '~/components/Table';
import TransferOS from '~/components/TransferOS';
import Loading from '~/components/Loading';

import banner from '~/assets/banners/banner.png';
import builder from '~/assets/icons/engineer.svg';
import seeMore from '~/assets/icons/see-more.svg';
import exportIcon from '~/assets/icons/export.svg';
import deleteIcon from '~/assets/icons/delete.svg';
import { formatPrice } from '~/utils/format';

let cancelToken;

export default function Home() {
  const { validError } = useValidError();
  const { user } = useAuth();
  const history = useHistory();
  const params = useParams();
  const [orders, setOrders] = useState([]);
  const [orderId, setOrderId] = useState('');
  const [totalData, setTotalData] = useState(0);
  const [fromData, setFromData] = useState(0);
  const [toData, setToData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pendency, setPendency] = useState('all');
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [filters, setFilters] = useState({
    initial_date: '',
    end_date: '',
    finality: 0,
    states: [],
    status: 0,
  });
  const [showTransfer, setShowTransfer] = useState(false);

  const name = useMemo(() => {
    const nameParts = user.name.split(' ');
    return `${nameParts[0]} ${nameParts[1]}`;
  }, [user.name]);

  const loadOSs = useCallback(
    async ({ page, pendencyData, searchData }) => {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('canceled');
      }

      setLoading(true);

      try {
        cancelToken = axios.CancelToken.source();

        let filterCorrespondent;
        if (params.slug === 'com-correspondente') {
          filterCorrespondent = 'with-correspondent';
        } else if (params.slug === 'sem-correspondente') {
          filterCorrespondent = 'without-correspondent';
        }

        const { initial_date, end_date, finality, state, states, status } =
          filters;

        const response = await api.get('correspondents/oss', {
          cancelToken: cancelToken.token,
          params: {
            page,
            filterCorrespondent,
            pendency: pendencyData,
            search: searchData,
            initial_date,
            end_date,
            finality,
            state,
            states,
            status,
          },
        });

        if (response.data) {
          const data = response.data.data.map(os => ({
            id: os.id,
            finality: os.finalidade ? os.finalidade.finalidade : '-',
            document_type: 'CPF',
            document: os.cliente ? os.cliente.documento : '',
            name: os.cliente ? os.cliente.nome : '',
            land_location:
              os.terreno && os.terreno.estado ? os.terreno.estado.sigla : '',
            builder: os.construtor ? os.construtor.name : '-',
            correspondent: os.correspondente ? os.correspondente.name : '-',
            status: os.status.status,
            finalidade: os.finalidade.finalidade,
            banco: os.simulacao?.amortizacao?.banco.banco,
            vlrSolicitado: os.simulacao?.vlrSolicitado,
            pendency:
              os.status.responsavel_id === 1 ? 'builder' : 'correspondent',
            created_at: format(parseISO(os.created_at), 'dd/MM/yyyy'),
          }));

          setOrders(data);
          setTotalData(response.data.total);
          setFromData(response.data.from);
          setToData(response.data.to);
          setSelectedPage(response.data.current_page);
          setLoading(false);
        }
      } catch (error) {
        if (error.message !== 'canceled') {
          validError(error);
          setLoading(false);
        }
      }
    },
    [filters, params.slug, validError],
  );

  useEffect(() => {
    loadOSs({
      pendencyData: 'all',
      searchData: '',
      page: 1,
    });
  }, [loadOSs]);

  const handleClick = useCallback(
    id => {
      if (orderId === id) {
        setOrderId('');
      } else {
        setOrderId(id);
      }
    },
    [orderId],
  );

  const handleClose = useCallback(() => {
    setOrderId('');
    setShowTransfer(false);
    loadOSs({
      pendencyData: pendency,
      searchData: search,
      page: selectedPage,
    });
  }, [loadOSs, pendency, search, selectedPage]);

  const handleShowTransfer = useCallback(value => {
    setType(value);
    setShowTransfer(true);
  }, []);

  const handleClickTransfer = useCallback(() => {
    Swal.fire({
      text: 'Para quem deseja tranferir essa OS?',
      icon: 'warning',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonColor: '#9ddef2',
      denyButtonColor: '#ffbe21',
      confirmButtonText: 'Transferir Correspondente',
      denyButtonText: `Transferir Construtor`,
      customClass: {
        denyButton: 'color-onyx-bold',
        confirmButton: 'color-onyx-bold',
      },
    }).then(result => {
      if (result.isConfirmed) {
        handleShowTransfer('');
      }
      if (result.isDenied) {
        handleShowTransfer('builders');
      }
    });
  }, [handleShowTransfer]);

  const columns = useMemo(
    () => [
      {
        name: '',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents" className="flex w-full justify-center">
            <Img
              data-tag="allowRowEvents"
              src={builder}
              alt="builder"
              pendency={row.pendency}
            />
          </div>
        ),
      },
      {
        name: '',
        selector: 'id',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              ID
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.id}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'finality',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Finalidade
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.finality}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'document',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              {row.document_type}
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.document}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'name',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Nome do Cliente
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.name}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'land_location',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Localização do terreno
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.land_location}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'builder',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Construtor
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.builder}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'correspondent',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Correspondente
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.correspondent}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'status',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Status
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.status}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'finalidade',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Finalidade
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.finalidade}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'banco',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Banco
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.banco}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'vlrSolicitado',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Valor
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {formatPrice(row.vlrSolicitado || 0)}
            </p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'created_at',
        sortable: false,
        cell: row => (
          <div data-tag="allowRowEvents">
            <p
              data-tag="allowRowEvents"
              className="font-semibold color-onyx mb-3"
            >
              Data de criação
            </p>
            <p
              data-tag="allowRowEvents"
              className={`font-normal ${
                row.vlrSolicitado >= 500000 ? 'color-blue' : 'color-gray'
              }`}
            >
              {row.created_at}
            </p>
          </div>
        ),
      },
      {
        name: '',
        sortable: false,
        cell: row => {
          if (
            user.isAdmin ||
            (user.isCorrespondente && user.isCorrespondente.avancado)
          ) {
            return (
              <SeeMore className="relative">
                <button
                  type="button"
                  className="flex items-center justify-center bg-transparent btn-see-more"
                  onClick={() => handleClick(row.id)}
                >
                  <img src={seeMore} alt="see-more" />
                </button>
                <Options
                  active={orderId === row.id}
                  className="absolute flex flex-col p-5"
                >
                  <button
                    type="button"
                    className="btn-export borders-none bg-transparent flex items-center justify-center font-medium"
                    onClick={handleClickTransfer}
                  >
                    <img src={exportIcon} alt="export" /> Transferir
                  </button>
                  <hr className="my-1" />
                  <button
                    type="button"
                    className="btn-delete border-none bg-transparent flex items-center justify-center font-medium"
                  >
                    <img src={deleteIcon} alt="delete" /> Deletar
                  </button>
                </Options>
              </SeeMore>
            );
          }
          return '';
        },
      },
    ],
    [
      handleClick,
      handleClickTransfer,
      orderId,
      user.isAdmin,
      user.isCorrespondente,
    ],
  );

  const handleChangePage = useCallback(
    value => {
      loadOSs({
        pendencyData: pendency,
        searchData: search,
        page: value,
      });
    },
    [loadOSs, pendency, search],
  );

  const handleClickPendency = useCallback(
    value => {
      let pendencyData = 'all';
      if (value !== 'all') {
        if (value === 'builder') {
          pendencyData = 1;
        } else {
          pendencyData = 2;
        }
      }

      loadOSs({
        pendencyData,
        searchData: search,
        page: 1,
      });
      setPendency(pendencyData);
    },
    [loadOSs, search],
  );

  const handleAccept = useCallback(
    async serviceOrderId => {
      setLoading(true);
      try {
        const response = await api.patch(
          `correspondents/oss/${serviceOrderId}`,
        );
        if (response.data) {
          history.push(
            `${process.env.PUBLIC_URL}/ordem-de-servico/${serviceOrderId}`,
          );
        }
      } catch (error) {
        validError(error);
      } finally {
        setLoading(false);
      }
    },
    [history, validError],
  );

  const handleRowClick = useCallback(
    order => {
      if (params.slug === 'com-correspondente') {
        history.push(`${process.env.PUBLIC_URL}/ordem-de-servico/${order.id}`);
      } else if (user.isAdmin) {
        history.push(`${process.env.PUBLIC_URL}/ordem-de-servico/${order.id}`);
      } else if (user.isCorrespondente) {
        if (user.isCorrespondente.avancado) {
          Swal.fire({
            text: 'Deseja se tornar responsável por essa OS?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#4DC68C',
            cancelButtonColor: '#F91818',
            denyButtonColor: '#999999',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            denyButtonText: `Acessar OS`,
            customClass: {
              denyButton: 'order-1',
              cancelButton: 'order-2',
              confirmButton: 'order-3',
            },
          }).then(result => {
            if (result.isConfirmed) {
              handleAccept(order.id);
            }
            if (result.isDenied) {
              history.push(
                `${process.env.PUBLIC_URL}/ordem-de-servico/${order.id}`,
              );
            }
          });
        } else {
          Swal.fire({
            text: 'Deseja se tornar responsável por essa OS?',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#4DC68C',
            cancelButtonColor: '#F91818',
            cancelButtonText: 'Não',
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              handleAccept(order.id);
            }
          });
        }
      }
    },
    [handleAccept, history, params.slug, user.isAdmin, user.isCorrespondente],
  );

  const handleSearch = useCallback(
    value => {
      loadOSs({
        pendencyData: pendency,
        searchData: value,
        page: 1,
      });
      setSearch(value);
    },
    [loadOSs, pendency],
  );

  const handleSubmitFilters = useCallback(filtersData => {
    setFilters(filtersData);
  }, []);

  return (
    <Container className="xl:container mx-auto">
      <div className="flex flex-wrap">
        <Banner src={banner} className="w-full md:w-9/12 px-7 pt-7 pb-32">
          <h1 className="text-2xl text-white mb-2">Olá, {name}</h1>
          <h2 className="color-android-green text-2xl">Bem-vindo ao harvey</h2>
        </Banner>
        <div className="hidden md:block pl-6 pt-4 w-full md:w-3/12">
          <div className="flex justify-end items-center">
            <MiniProfile />
          </div>
        </div>
      </div>
      <div className="table-style">
        <Table
          title={`Ordem de serviço: ${
            params.slug === 'com-correspondente'
              ? 'correspondente'
              : 'Sem correspondente'
          }`}
          searchable
          onSearch={handleSearch}
          columns={columns}
          data={orders}
          pagination
          onRowClicked={handleRowClick}
          onClickPendency={handleClickPendency}
          slug={params.slug}
          totalData={totalData}
          fromData={fromData}
          toData={toData}
          selectedPage={selectedPage}
          onChangePage={handleChangePage}
          onSubmitFilters={handleSubmitFilters}
        />
      </div>
      {showTransfer && (
        <TransferOS
          type={type}
          osId={orderId}
          show={showTransfer}
          onClose={handleClose}
        />
      )}
      <Loading active={loading} />
    </Container>
  );
}
