import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Input({
  name,
  hasError,
  className,
  type,
  onFocus,
  onBlur,
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [typeInput, setTypeInput] = useState('password');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  const handleInputFocus = useCallback(
    e => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true);
    },
    [onFocus],
  );

  const handleInputBlur = useCallback(
    e => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur],
  );

  const handleClick = useCallback(() => {
    setTypeInput(state => (state === 'password' ? 'text' : 'password'));
  }, []);

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          {type !== 'password' ? (
            <input
              name={name}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={defaultValue}
              ref={inputRef}
              type={type || 'text'}
              {...rest}
            />
          ) : (
            <input
              name={name}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={defaultValue}
              ref={inputRef}
              type={typeInput}
              {...rest}
            />
          )}
          {type === 'password' && (
            <button
              type="button"
              className="button-show flex justify-center items-center"
              onClick={handleClick}
            >
              {typeInput === 'password' ? (
                <BsEye size={20} color="#91969B" />
              ) : (
                <BsEyeSlash size={20} color="#91969B" />
              )}
            </button>
          )}
        </div>
      </Container>
      {error && (
        <span className="d-block w-full text-sm text-danger error mt-2">
          {error}
        </span>
      )}
    </>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  hasError: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  type: '',
  className: '',
  hasError: '',
  onFocus: () => {},
  onBlur: () => {},
};
