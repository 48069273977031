import styled from 'styled-components';
import { Modal as ModalComponent } from '~/components/Modal';

export const Container = styled.section`
  .table-style {
    .rdt_TableHeader {
      display: none !important;
    }

    .rdt_TableHeadRow {
      display: none;
    }

    .rdt_TableRow {
      > div {
        display: flex;
        align-items: start;
        width: 200px !important;
        min-width: 200px !important;
        max-width: 200px !important;
      }

      > div:nth-child(1),
      > div:nth-child(11) {
        width: 75px !important;
        min-width: 75px !important;
        max-width: 75px !important;
        align-self: center;
      }
    }
  }
`;

export const Banner = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: right;
`;

export const Img = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  background-color: ${props =>
    props.pendency === 'correspondent' ? '#9DDEF2' : '#F4C004'};
`;

export const SeeMore = styled.div`
  .btn-see-more {
    width: 50px;
    height: 50px;
  }
`;

export const Options = styled.div`
  background: #b8b8b8;
  border-radius: 10px;
  width: 195px;
  top: ${props => (props.active ? '-30px' : '-40px')};
  left: -200px;
  z-index: 100;
  transition-duration: 0.3s;
  opacity: ${props => (props.active ? '1' : '0')};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};

  hr {
    border-color: #464646;
  }

  .btn-export {
    color: #606060;
  }

  .btn-delete {
    color: #ff1a50;
  }
`;

export const Modal = styled(ModalComponent)`
  .modalContent {
    min-width: 600px;

    .options {
      min-height: 242px;
    }

    button.btn-disapproved,
    button.btn-approved {
      color: #ffffff;
      font-weight: 700;
    }

    button.btn-disapproved {
      background-color: #f6002c;
    }

    button.btn-approved {
      background-color: #079269;
    }
  }
`;
