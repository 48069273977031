import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import api from '~/services/api';

import { Container, Item, Progress } from './styles';
import Carousel from '~/components/Carousel';

export default function Status({ step }) {
  const [steps, setSteps] = useState([]);
  const [percent, setPercent] = useState([]);

  useEffect(() => {
    api.get('correspondents/oss/steps').then(response => {
      const data = response.data.map(stepData => ({
        id: stepData.id,
        ordem: stepData.ordem,
        name: stepData.step,
      }));
      setPercent(((step * 100) / data.length).toFixed(0));
      setSteps(data);
    });
  }, [step]);

  return (
    <Container className="w-full p-5">
      <div className="w-full">
        <Carousel itemActive={step > 3 ? step - 3 : 0}>
          {steps.map(stepData => (
            <Item active={step === stepData.ordem}>
              <div className="text-center mx-5">
                <p className="text-2xl font-semibold step">{stepData.ordem}</p>
                <p className="font-medium">{stepData.name}</p>
              </div>
            </Item>
          ))}
        </Carousel>
      </div>
      <div className="flex items-center px-5">
        <p>0%</p>
        <Progress percent={percent} className="w-full relative mx-3" />
        <p>100%</p>
      </div>
    </Container>
  );
}

Status.propTypes = {
  step: PropTypes.string.isRequired,
};
