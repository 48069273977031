import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Content } from './styles';
import Header from '~/components/Header';

export default function DefaultLayout({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="lg:flex">
      <Header />
      <Content>{children}</Content>
    </div>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
