import styled from 'styled-components';

export const Container = styled.div``;

export const Placeholder = styled.div`
  background: #f5f5f6;
  border: 1px dashed #aaaaaa;
  border-radius: 18px;
`;

export const FilesList = styled.div`
  max-height: 302px;
  overflow: auto;

  li {
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 26px);
      text-align: left;
    }
    + li {
      border-top: 1px solid #eaeaea;
    }
  }
`;
