import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Select({
  name,
  hasError,
  className,
  options,
  onFocus,
  onBlur,
  readOnly,
  onChange,
  cleanField,
  ...rest
}) {
  const inputRef = useRef(null);
  const [values, setValues] = useState(options);
  const [optionSelected, setOptionSelected] = useState(0);
  const [valueSelected, setValueSelected] = useState('');
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }

    if (options) {
      setValues(options);
      const selectedOption = options.find(option => option.selected);
      if (selectedOption) {
        setOptionSelected(selectedOption.id);
        setValueSelected(selectedOption.value);
      }
    }
  }, [error, hasError, options]);

  const handleInputFocus = useCallback(
    e => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true && !readOnly);
    },
    [onFocus, readOnly],
  );

  const handleInputBlur = useCallback(
    e => {
      if (onBlur) {
        onBlur(e);
      }
      setTimeout(() => {
        setIsFocuses(false);
      }, 300);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur],
  );

  const handleChange = useCallback(
    e => {
      const { value } = e.target;
      const search = new RegExp(`${value.toUpperCase()}`);
      const newValuesList = options.filter(option =>
        option.value.toUpperCase().match(search),
      );
      setValueSelected(value);
      setValues(newValuesList);
    },
    [options],
  );

  const handleClick = useCallback(
    option => {
      setOptionSelected(option.id);
      setValueSelected(option.value);
      if (onChange) {
        onChange(option);
      }
      if (cleanField) {
        setOptionSelected(0);
        setValueSelected('');
      }
    },
    [cleanField, onChange],
  );

  return (
    <>
      <Container
        className={`${className}`}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleChange}
            autoComplete="off"
            value={valueSelected}
            readOnly={readOnly}
            {...rest}
          />
          <input
            type="hidden"
            name={name}
            defaultValue={defaultValue}
            ref={inputRef}
            value={optionSelected}
          />
        </div>
        {isFocuses && (
          <div className="options">
            {values.length > 0 ? (
              <>
                {values.map(option => (
                  <button
                    key={option.id}
                    type="button"
                    onClick={() => handleClick(option)}
                    className="w-full"
                  >
                    {option.value}
                  </button>
                ))}
              </>
            ) : (
              <span className="w-full">Nenhum valor disponivel</span>
            )}
          </div>
        )}
      </Container>
      {error && <span className="small text-danger error">{error}</span>}
    </>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  readOnly: PropTypes.bool,
  cleanField: PropTypes.bool,
  className: PropTypes.string,
  hasError: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  readOnly: false,
  cleanField: false,
  className: '',
  hasError: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
};
