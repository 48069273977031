import styled from 'styled-components';

export const Container = styled.div`
  width: 21px;
  height: 21px;
  background: transparent;
  border: 1px solid ${props => (props.checked ? '#a1c64d' : '#AAAAAA')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;

  .d-none {
    display: none !important;
  }
`;

export const CircleFill = styled.div`
  background: #a1c64d;
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;
