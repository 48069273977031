import React, { useEffect, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useValidError } from '~/hooks/ValidError';

import { Container } from './styles';

export default function WarningBox({
  slug,
  statusId,
  onShowBankSlip,
  onShowAttachEngineeringReport,
  onShowSubmitRegistrationForms,
  onShowReleaseSignature,
}) {
  const { validError } = useValidError();
  const [warningData, setWarningData] = useState({});

  useEffect(() => {
    let warning = {};
    switch (statusId) {
      case 4:
        warning = {
          title: 'Está pendente analise de arquivos',
          message: 'Está pendente análise do correspondente para os arquivos',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'link',
              to: `${process.env.PUBLIC_URL}/documentos/verificar-documentos/${slug}`,
              content: 'Análisar arquivos',
            },
          ],
        };
        break;

      case 6:
        warning = {
          title: 'Está pendente analise de crédito',
          message: 'Está pendente análise de crédito do correspondente',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'link',
              to: `${process.env.PUBLIC_URL}/documentos/resultado-analise-de-credito/${slug}`,
              content: 'Análisar crédito',
            },
          ],
        };
        break;

      case 10:
        warning = {
          title: 'Análise de abertura de processo',
          message: 'Está pendente análise da abertura do processo',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'link',
              to: `${process.env.PUBLIC_URL}/documentos/analise-de-abertura-de-processo/${slug}`,
              content: 'Análisar abertura',
            },
          ],
        };
        break;

      case 12:
        warning = {
          title: 'Subir boleto  de engenharia',
          message: 'Está pendente subir boleto  de engenharia',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'button',
              handleClick: onShowBankSlip,
              content: 'Subir Boleto',
            },
          ],
        };
        break;

      case 14:
        warning = {
          title: 'Anexar laudo de engenheiro',
          message: 'Está pendente o anexo do laudo de engenheiro',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'button',
              handleClick: onShowAttachEngineeringReport,
              content: 'Anexar laudo',
            },
          ],
        };
        break;

      case 16:
        // warning = {
        //   title: 'Resultado da conformidade',
        //   message: 'Verifique o resultado da conformidade',
        //   warningIcon: true,
        //   borderColor: '#ffbe21',
        //   buttons: [
        //     {
        //       type: 'link',
        //       to: `${process.env.PUBLIC_URL}/documentos/resultado-da-conformidade/${slug}`,
        //       content: 'Aprovado',
        //     },
        //     {
        //       type: 'link',
        //       to: `${process.env.PUBLIC_URL}/documentos/resultado-da-conformidade/${slug}`,
        //       content: 'Com pendências',
        //     },
        //   ],
        // };

        warning = {
          title: 'Pendência respondida pelo construtor',
          message: 'Verificar documentos e anexar no sistema',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'button',
              handleClick: onShowAttachEngineeringReport,
              content: 'Anexar laudo',
            },
          ],
        };
        break;

      case 17:
        warning = {
          title: 'Enviar fichas cadastrais',
          message: 'Enviar fichas cadastrais para o construtor',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'button',
              handleClick: onShowSubmitRegistrationForms,
              content: 'Enviar Fichas',
            },
          ],
        };
        break;

      case 19:
        warning = {
          title: 'Baixar fichas cadastrais assinadas',
          message:
            'O construtor enviou as fichas cadastrais, você já pode baixar',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'link',
              to: `${process.env.PUBLIC_URL}/documentos/fichas-cadastrais/${slug}`,
              content: 'Baixar Fichas',
            },
          ],
        };
        break;

      case 21:
        warning = {
          title: 'Liberar assinatura',
          message: 'Para finalizar, libere a assinatura',
          warningIcon: false,
          borderColor: '#ff3c21',
          buttons: [
            {
              type: 'button',
              handleClick: onShowReleaseSignature,
              content: 'Liberar Assinatura',
            },
          ],
        };
        break;

      case 23:
      case 24:
        warning = {
          title: 'Verificar pedido de medição',
          message: 'Para finalizar, libere a assinatura',
          warningIcon: true,
          borderColor: '#ffbe21',
          buttons: [
            {
              type: 'link',
              to: `${process.env.PUBLIC_URL}/medicoes/${slug}`,
              content: 'Medições',
            },
          ],
        };
        break;

      default:
        warning = {};
        break;
    }

    setWarningData(warning);
  }, [
    onShowAttachEngineeringReport,
    onShowBankSlip,
    onShowReleaseSignature,
    onShowSubmitRegistrationForms,
    slug,
    statusId,
    validError,
  ]);

  return (
    <>
      {Object.keys(warningData).length > 0 && (
        <Container
          color={warningData.borderColor}
          className="p-6 md:p-8 mb-5 md:mb-10"
        >
          <div className="flex justify-between">
            <h3 className="w-10/12 md:w-auto text-lg md:text-xl font-bold mb-5">
              {warningData.title}
            </h3>
            {warningData.warningIcon && (
              <IoIosWarning size={20} color="#FFBE21" />
            )}
          </div>
          <div className="md:flex justify-between items-center">
            <p className="mb-2 md:mb-0">{warningData.message}</p>
            <div>
              {warningData.buttons.map(buttonData => (
                <>
                  {buttonData.type === 'link' ? (
                    <>
                      {buttonData.downloadable ? (
                        <a
                          href={buttonData.to}
                          className="color-onyx px-5 py-2 w-full md:w-auto"
                          download
                        >
                          {buttonData.content}
                        </a>
                      ) : (
                        <Link
                          to={buttonData.to}
                          className="color-onyx px-5 py-2 w-full md:w-auto"
                        >
                          {buttonData.content}
                        </Link>
                      )}
                    </>
                  ) : (
                    <button
                      type="button"
                      className="color-onyx px-5 py-2 w-full md:w-auto"
                      onClick={buttonData.handleClick}
                    >
                      {buttonData.content}
                    </button>
                  )}
                </>
              ))}
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

WarningBox.propTypes = {
  slug: PropTypes.string.isRequired,
  statusId: PropTypes.number.isRequired,
  onShowBankSlip: PropTypes.func,
  onShowAttachEngineeringReport: PropTypes.func,
  onShowSubmitRegistrationForms: PropTypes.func,
  onShowReleaseSignature: PropTypes.func,
};

WarningBox.defaultProps = {
  onShowBankSlip: () => {},
  onShowAttachEngineeringReport: () => {},
  onShowSubmitRegistrationForms: () => {},
  onShowReleaseSignature: () => {},
};
