import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Textarea({
  name,
  hasError,
  className,
  onFocus,
  onBlur,
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  const handleTextareaFocus = useCallback(
    e => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true);
    },
    [onFocus],
  );

  const handleTextareaBlur = useCallback(
    e => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur],
  );

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          <textarea
            name={name}
            onFocus={handleTextareaFocus}
            onBlur={handleTextareaBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
          />
        </div>
      </Container>
      {error && (
        <span className="d-block w-full text-sm text-danger error mt-2">
          {error}
        </span>
      )}
    </>
  );
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  hasError: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

Textarea.defaultProps = {
  className: '',
  hasError: '',
  onFocus: () => {},
  onBlur: () => {},
};
