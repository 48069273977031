import styled, { css } from 'styled-components';
import { Link as LinkComponent } from 'react-router-dom';

import serviceOrder from '~/assets/icons/service-order.svg';
import serviceOrderActive from '~/assets/icons/service-order-active.svg';
import checkDocuments from '~/assets/icons/check-documents.svg';
import checkDocumentsActive from '~/assets/icons/check-documents-active.svg';
import creditAnalysisResult from '~/assets/icons/credit-analysis-result.svg';
import creditAnalysisResultActive from '~/assets/icons/credit-analysis-result-active.svg';
import processOpeningAnalysisProposal from '~/assets/icons/process-opening-analysis.svg';
import processOpeningAnalysisProposalActive from '~/assets/icons/process-opening-analysis-active.svg';
import uploadEngineeringTicket from '~/assets/icons/upload-engineering-ticket.svg';
import uploadEngineeringTicketActive from '~/assets/icons/upload-engineering-ticket-active.svg';
import attachEngineeringReport from '~/assets/icons/attach-engineering-report.svg';
import attachEngineeringReportActive from '~/assets/icons/attach-engineering-report-active.svg';
import complianceResult from '~/assets/icons/compliance-result.svg';
import complianceResultActive from '~/assets/icons/compliance-result-active.svg';
import registrationForms from '~/assets/icons/registration-forms.svg';
import registrationFormsActive from '~/assets/icons/registration-forms-active.svg';
import releaseContractSignature from '~/assets/icons/release-contract-signature.svg';
import releaseContractSignatureActive from '~/assets/icons/release-contract-signature-active.svg';
import downloadProposal from '~/assets/icons/download-proposal.svg';
import downloadProposalActive from '~/assets/icons/download-proposal-active.svg';
import fileManager from '~/assets/icons/file-manager.svg';
import fileManagerActive from '~/assets/icons/file-manager-active.svg';
import menu from '~/assets/icons/menu.svg';
import menuActive from '~/assets/icons/menu-active.svg';

// export const Cont = styled.nav`
//   .wrapper {
//     position: fixed;
//     z-index: 10;
//   }

//   ul {
//     width: 78px;
//     max-height: 100%;
//     margin: 0 !important;
//     overflow-x: hidden;
//     overflow-y: auto;
//   }

//   li,
//   a {
//     position: static;

//     // submenu
//     .wrapper {
//       position: absolute;
//       transition-duration: 0.3s;
//       z-index: 10;
//       left: -100%;
//       width: max-content;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       border-radius: 0 5px 5px 0;
//       text-align: left;
//       background: #ffffff;
//       color: #999999;
//       padding: 0 20px;
//       min-height: 50px;
//     }

//     :hover {
//       .wrapper {
//         left: 100%;
//       }
//     }
//   }

//   ul {
//     margin: 1em;
//     color: white;
//     font-family: sans-serif;
//     font-size: 16px;
//   }

//   li,
//   a {
//     padding: 1em;

//     ul {
//       margin: 0;
//     }

//     .wrapper {
//       cursor: auto;
//       li {
//         padding: 0.5em;
//       }
//     }

//     &:nth-child(2n) {
//       background: #0e8ce0;
//     }
//     &:nth-child(2n + 1) {
//       background: #0064b3;
//     }
//     &.parent {
//       background: #00b99b;
//       cursor: pointer;
//     }
//   }
// `;

export const Container = styled.nav`
  width: 78px;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 66px;
  }
`;

export const Content = styled.div`
  /* position: fixed; */
  /* top: 0; */
  background: #ffffff;
  z-index: 200;
  width: 100%;
  min-height: 800px;
  height: 100vh;

  @media screen and (max-width: 991px) {
    height: 66px;
    left: 0;
  }

  @media screen and (min-width: 992px) {
    width: 78px !important;
  }
`;

export const MenuMobileButton = styled.button`
  width: 30px;
  height: 30px;

  span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #9c9a9a;
    border-radius: 30px;
    transition-duration: 0.3s;

    :nth-child(2) {
      width: 90%;
      margin: 6px 0 6px auto;
    }

    ${props =>
      props.active &&
      css`
        :nth-child(1) {
          transform: rotate(45deg);
        }

        :nth-child(2) {
          opacity: 0;
        }

        :nth-child(3) {
          transform: rotate(-45deg);
          margin-top: -18px;
        }
      `}
  }
`;

export const Menu = styled.div`
  height: 100%;

  .min-height {
    min-height: 50px;
  }

  .menu-group {
    height: calc(100% - 100px);
  }

  .menu,
  .logoff {
    a,
    button {
      background: #ffffff;
      color: #999999;
      position: relative;

      div {
        width: 22px;
        height: 22px;
        transition-duration: 0.3s;
      }

      .order-of-service {
        background-image: url(${serviceOrder});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .check-documents {
        background-image: url(${checkDocuments});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .credit-analysis-result {
        background-image: url(${creditAnalysisResult});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .process-opening-analysis {
        background-image: url(${processOpeningAnalysisProposal});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .upload-engineering-ticket {
        background-image: url(${uploadEngineeringTicket});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .attach-engineering-report {
        background-image: url(${attachEngineeringReport});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .compliance-result {
        background-image: url(${complianceResult});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .registration-forms {
        background-image: url(${registrationForms});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .release-contract-signature {
        background-image: url(${releaseContractSignature});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .download-proposal {
        background-image: url(${downloadProposal});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .file-manager {
        background-image: url(${fileManager});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .logout {
        background-image: url(${menu});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      small {
        position: absolute;
        transition-duration: 0.3s;
        z-index: -1;
        left: -150px;
        width: max-content;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 5px 5px 0;
        text-align: left;
        background: #ffffff;
        color: #999999;
      }
    }

    a:hover,
    button:hover,
    a.active {
      .order-of-service {
        background-image: url(${serviceOrderActive});
      }

      .check-documents {
        background-image: url(${checkDocumentsActive});
      }

      .credit-analysis-result {
        background-image: url(${creditAnalysisResultActive});
      }

      .process-opening-analysis {
        background-image: url(${processOpeningAnalysisProposalActive});
      }

      .upload-engineering-ticket {
        background-image: url(${uploadEngineeringTicketActive});
      }

      .attach-engineering-report {
        background-image: url(${attachEngineeringReportActive});
      }

      .compliance-result {
        background-image: url(${complianceResultActive});
      }

      .registration-forms {
        background-image: url(${registrationFormsActive});
      }

      .release-contract-signature {
        background-image: url(${releaseContractSignatureActive});
      }

      .download-proposal {
        background-image: url(${downloadProposalActive});
      }

      .file-manager {
        background-image: url(${fileManagerActive});
      }

      .logout {
        background-image: url(${menuActive});
      }

      small {
        text-decoration: none;
        color: #a1c64d;
      }
    }

    a:hover,
    button:hover {
      small {
        padding: 0 20px;
        left: 100%;
        min-height: 50px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    height: 100%;
    background: #fff;
    visibility: ${props => (props.active ? 'visible' : 'hidden')};

    .menu-group {
      width: 100%;
      height: calc(100% - 75px);
      overflow: auto;
      background: #ffffff;
      transition-duration: 0.3s;
      left: ${props => (props.active ? '0' : '1000%')};
    }

    .menu,
    .logoff {
      a,
      button {
        div {
          margin: 0 10px;
        }

        small {
          position: relative;
          z-index: 0;
          text-align: left;
          opacity: 1;
          justify-content: center;
          left: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
`;

export const Link = styled(LinkComponent)`
  cursor: ${props => (props.released ? 'pointer' : 'not-allowed')};

  ${props =>
    !props.released &&
    css`
      :active {
        pointer-events: none;
      }
    `}
`;

export const Button = styled.button`
  cursor: ${props => (props.released ? 'pointer' : 'not-allowed')};

  ${props =>
    !props.released &&
    css`
      :active {
        pointer-events: none;
      }
    `}
`;
