import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function PriceChart({ value, color, className }) {
  const options = useMemo(() => {
    const data = {
      colors: [color],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            size: '65%',
          },
          track: {
            background: '#BDBDBD',
            startAngle: -90,
            endAngle: 90,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: '18px',
              fontWeight: 'bold',
              offsetY: -5,
              show: true,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Progress'],
    };

    return data;
  }, [color]);

  return (
    <Container className={`${className} relative`}>
      <div className="graph absolute">
        <ReactApexChart
          options={options}
          series={[value]}
          type="radialBar"
          height={175}
        />
      </div>
    </Container>
  );
}

PriceChart.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

PriceChart.defaultProps = {
  color: '#ff7777',
  className: '',
};
