import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import api from '../services/api';

export const AuthContext = createContext({
  user: {},
  signIn: () => {},
  autoSignIn: () => {},
  signUp: () => {},
  signOut: () => {},
  updateUser: () => {},
  setLogin: () => {},
});

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Harvey:token');
    const user = localStorage.getItem('@Harvey:user');

    if (token && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(
    async ({ email, password, destination }) => {
      const response = await api.post('correspondents/session', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@Harvey:token', token);
      localStorage.setItem('@Harvey:user', JSON.stringify(user));

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setData({ token, user });
      if (destination) {
        history.push(`${process.env.PUBLIC_URL}/${destination}`);
      }
    },
    [history],
  );

  const autoSignIn = useCallback(
    async (hash, destination) => {
      try {
        const response = await api.post(`correspondents/session/auto-login`, {
          hash,
        });

        const { token, user } = response.data;

        localStorage.setItem('@Harvey:token', token);
        localStorage.setItem('@Harvey:user', JSON.stringify(user));

        api.defaults.headers.Authorization = `Bearer ${token}`;

        setData({ token, user });
        if (destination) {
          history.push(`${process.env.PUBLIC_URL}/${destination}`);
        }
      } catch (error) {
        Swal.fire('Opss...', 'Dados de login inválidos!', 'error');
      }
    },
    [history],
  );

  const signUp = useCallback(
    async ({ name, email, password, referenced }) => {
      await api.post('correspondents/session/create', {
        name,
        email,
        password,
        referenced,
      });

      signIn({ email, password });
    },
    [signIn],
  );

  const signOut = useCallback(() => {
    const cacheToken = localStorage.getItem('@Harvey:cacheToken');
    localStorage.removeItem('@Harvey:token');
    localStorage.removeItem('@Harvey:user');
    localStorage.removeItem('@Harvey:cacheToken');
    if (cacheToken) {
      api.get(`correspondents/session/remove-data/${cacheToken}`);
    }

    setData({});
  }, []);

  const updateUser = useCallback(
    user => {
      localStorage.setItem('@Harvey:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  const setLogin = useCallback(
    (token, user, cacheToken) => {
      localStorage.setItem('@Harvey:token', token);
      localStorage.setItem('@Harvey:user', JSON.stringify(user));
      localStorage.setItem('@Harvey:cacheToken', cacheToken);

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setData({
        token,
        user,
      });
    },
    [setData],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        autoSignIn,
        signUp,
        signOut,
        updateUser,
        setLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
