import styled, { css } from 'styled-components';

export const Container = styled.button`
  background: #ffffff;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: block;
  padding: 20px 30px;
  cursor: pointer;
  border: 1px solid ${props => props.statusColor};

  ${props =>
    props.approved.length > 0 &&
    css`
      border: 1px solid ${props.approved === 'Aprovado' ? '#A1C64D' : '#ff2626'};
    `}

  h3 {
    color: #8d8d8e;
  }

  p {
    color: #414142;
    font-weight: 600;
    text-decoration: underline;
  }

  .approved {
    color: #a1c64d;
  }

  .disapproved {
    color: #ff2626;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;
