import React, { useMemo } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import { Container, Button } from './styles';

function PrevArrow({ className, onClick }) {
  return (
    <Button
      type="button"
      className={`${className} h-full absolute`}
      onClick={onClick}
    >
      <FaChevronLeft />
    </Button>
  );
}

function NextArrow({ className, onClick }) {
  return (
    <Button
      type="button"
      className={`${className} h-full absolute`}
      onClick={onClick}
      right
    >
      <FaChevronRight />
    </Button>
  );
}

export default function Carousel({ children, itemActive }) {
  const settings = useMemo(
    () => ({
      className: 'center',
      infinite: false,
      slidesToShow: 5,
      initialSlide: itemActive,
      speed: 500,
      arrows: true,
      touchMove: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
            centerPadding: '0px',
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            centerPadding: '0px',
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            centerPadding: '0px',
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
          },
        },
      ],
    }),
    [],
  );

  return (
    <Container>
      <Slider {...settings}>{children}</Slider>
    </Container>
  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  className: '',
  onClick: () => {},
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  itemActive: PropTypes.number.isRequired,
};
