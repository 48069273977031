import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Button = styled.button`
  width: 50px;
  background: ${props =>
    props.right
      ? 'linear-gradient(-90deg,#ffffff 25%,rgba(255,255,255,0.1) 75%)'
      : 'linear-gradient(90deg,#ffffff 25%,rgba(255,255,255,0.1) 75%)'} !important;

  border-radius: 0;
  z-index: 100;
  height: 120px;
  top: 40px;
  padding: 0 10px;

  ${props =>
    props.right
      ? css`
          right: -20px;
        `
      : css`
          left: -20px;
        `}

  svg {
    width: 20px;
    height: 20px;
    color: #414142;
  }

  ::before {
    display: none;
  }

  :hover {
    opacity: 1;
  }
`;
