import styled from 'styled-components';

export const Container = styled.div`
  .box {
    border: 1px solid #cccccc;
    border-radius: 10px;

    .bg-gray {
      background: #e8e4e4;
      border-radius: 10px 10px 0px 0px;

      button {
        background: #c4c4c4;
        color: #444444;
        font-weight: 600;
      }
    }

    textarea,
    textarea::placeholder {
      color: #8a8b8b;
    }
  }

  .px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
`;
