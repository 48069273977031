import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { useValidError } from '~/hooks/ValidError';
import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import {
  Container,
  Background,
  AnimationContainer,
  BoxButton,
  Box,
} from './styles';
import InputRadio from '~/components/InputRadio';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Loading from '~/components/Loading';

import logo from '~/assets/logos/logo-login.png';

export default function CreditAnalysisResult() {
  const { validError } = useValidError();
  const params = useParams();
  const history = useHistory();
  const { user } = useAuth();
  const formRef = useRef(null);
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    setLoading(true);
    api
      .get(`correspondents/oss/analysis/${params.slug}`)
      .then(response => {
        const analysisData = {
          id: response.data.id,
          result: response.data.resultado_analise
            ? response.data.resultado_analise.resultado
            : '',
          approved_value: formatPrice(response.data.vlrAprovado),
          comment: response.data.comentario_analise,
        };
        setFileName(response.data.arquivo ? response.data.arquivo.nome : '-');
        setAnalysis(analysisData);
      })
      .catch(error => {
        validError(error);
      })
      .finally(() => setLoading(false));
  }, [params.slug, validError]);

  const RadioOptions = useMemo(
    () => [
      {
        label: 'Análise de crédito aprovada',
        id: 'approved',
        value: 'approved',
        selected:
          analysis.result === 'Aprovada' ||
          analysis.result === 'approved' ||
          analysis.result === 'Análise de crédito aprovada',
      },
      {
        label: 'Análise de crédito condicionada',
        id: 'conditioned',
        value: 'conditioned',
        selected:
          analysis.result === 'Condicionada' ||
          analysis.result === 'conditioned' ||
          analysis.result === 'Analise de credito condicionada',
      },
      {
        label: 'Análise de crédito reprovada',
        id: 'disapproved',
        value: 'disapproved',
        selected:
          analysis.result === 'Reprovada' ||
          analysis.result === 'disapproved' ||
          analysis.result === 'Analise de credito reprovada',
      },
    ],
    [analysis.result],
  );

  const handleChange = useCallback(e => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }, []);

  const handleSubmit = useCallback(
    async data => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          result: Yup.string().required('Selecione uma das opções acima'),
          price: Yup.string().required('Valor do credito é obrigatório'),
          file: Yup.string().when('$fileData', {
            is: fileData => fileData,
            then: Yup.string(),
            otherwise: Yup.string().required('Selecione um arquivo'),
          }),
          observation: Yup.string().when('$observationRequired', {
            is: observationRequired => observationRequired,
            then: Yup.string().required(
              'A observação é obrigatória quando a análise é reprovada.',
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            fileData: !!fileName,
            observationRequired: data.result === 'disapproved',
          },
        });

        if (file) {
          const fileData = new FormData();
          fileData.append('file', file);
          fileData.append('os_id', params.slug);
          fileData.append('tpArquivo_id', 13);
          fileData.append('user_create_id', user.id);
          fileData.append('user_update_id', user.id);
          fileData.append('obsCorresp', data.observation);
          if (data.result === 'approved' || data.result === 'conditioned') {
            fileData.append('resultado_id', 1);
          } else {
            fileData.append('resultado_id', 2);
          }

          await api.post('correspondents/files', fileData);
        }

        let result;

        switch (data.result) {
          case 'approved':
            result = 1;
            break;
          case 'conditioned':
            result = 2;
            break;
          default:
            result = 3;
            break;
        }

        const price = data.price
          .replace('R$', '')
          .replaceAll(/\./g, '')
          .replace(',', '.');

        const formData = {
          result,
          price,
          comment: data.observation,
        };

        const response = await api.patch(
          `correspondents/oss/analysis/${analysis.id}`,
          formData,
        );

        if (response.data) {
          analysis.result = data.result;
          analysis.approved_value = formatPrice(price);
          setAnalysis(analysis);

          const responseOS = await api.get(`correspondents/oss/${params.slug}`);

          if (responseOS.data && responseOS.data.status.id === 6) {
            if (data.result === 'approved' || data.result === 'conditioned') {
              const formDataStep = {
                status_id: 8,
              };
              await api.put(`correspondents/oss/${params.slug}`, formDataStep);
            } else if (data.result === 'disapproved') {
              const formDataStep = {
                status_id: 7,
              };
              await api.put(`correspondents/oss/${params.slug}`, formDataStep);
            }
          }

          Swal.fire(
            'Tudo Certo!',
            'Resultado salvo com sucesso.',
            'success',
          ).then(() =>
            history.push(
              `${process.env.PUBLIC_URL}/ordem-de-servico/${params.slug}`,
            ),
          );
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          validError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [analysis, file, fileName, history, params.slug, user.id, validError],
  );

  return (
    <Container>
      <div className="w-full h-full">
        <div className="flex flex-row justify-end h-full">
          <Background className="hidden md:block w-3/12 p-8">
            <img src={logo} alt="Logo branca" />
          </Background>
          <AnimationContainer className="w-full md:w-9/12 md:p-8">
            <div className="flex flex-wrap justify-between items-start w-full">
              <div className="w-full md:w-10/12 flex items-start order-1 md:order-0">
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="border-0 bg-transparent mr-3"
                >
                  <IoIosArrowBack size={25} color="#CCCCCC" />
                </button>
                <div>
                  <h1 className="text-2xl color-onyx font-semibold">
                    Resultado da análise de crédito
                  </h1>
                  <p>Envie documentos referente a análise de crédito</p>
                </div>
              </div>
              <div className="w-full md:w-2/12 flex md:flex-col md:items-end text-center mb-4 order-0 md:order-1 p-4 md:px-0">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            {Object.keys(analysis).length > 0 && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <div className="w-full lg:flex mt-8 md:mt-16 px-4 md:px-8">
                  <InputRadio name="result" options={RadioOptions} />
                </div>
                <div className="w-full flex flex-col mt-10 px-4 md:px-8">
                  <p className="mb-3">Enviar arquivo</p>
                  <BoxButton
                    htmlFor="file"
                    className="w-full lg:w-5/12 flex items-center justify-between p-4"
                  >
                    <p className="ml-3">{fileName || '-'}</p>
                    <AiOutlinePaperClip size={30} color="#A1C64D" />
                    <Input
                      type="file"
                      name="file"
                      id="file"
                      className="d-none"
                      onChange={handleChange}
                      accept="image/*,application/pdf,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </BoxButton>
                </div>
                <div className="w-full flex flex-wrap mt-6 md:mt-10 px-4 md:px-8">
                  <div className="w-full lg:w-5/12 order-0 lg:mr-5">
                    <p className="mb-3">Valor do credito aprovado</p>
                  </div>
                  <div className="w-full lg:w-5/12 order-1 lg:order-0 mt-6 lg:mt-0">
                    <p className="mb-3">Observações</p>
                  </div>
                  <div className="w-full lg:w-5/12 order-0 lg:order-1 lg:mr-5">
                    <Box className="flex items-center justify-between p-4 h-full">
                      <InputMask
                        kind="money"
                        options={{
                          unit: 'R$',
                          delimiter: '.',
                          separator: ',',
                        }}
                        name="price"
                        className="border-none"
                        value={analysis.approved_value}
                      />
                    </Box>
                  </div>
                  <div className="w-full lg:w-5/12 order-1">
                    <Box className="flex items-center justify-between p-2 h-full">
                      <Input name="observation" className="border-none" />
                    </Box>
                  </div>
                </div>
                <div className="w-full flex justify-start my-10 md:mb-0 px-4 md:px-8">
                  <button
                    type="submit"
                    className="flex items-center justify-center w-full lg:w-4/12 text-white border-none py-2 rounded-full text-lg mr-2 bg-android-green font-bold"
                  >
                    Salvar resultado de analise
                  </button>
                </div>
              </Form>
            )}
          </AnimationContainer>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
}
