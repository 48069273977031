import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #D2D2D2;
  border-radius: 4px;
}

*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body{
  -webkit-font-smoothing: antialiased;
}

body, input, button {
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  color: #9C9C9C;
  background-color: #F2F5F7;
}

a{
  box-shadow: none !important
}


  .color-android-green{
    color: #A1C64D;
  }

  .bg-android-green{
    background-color: #A1C64D;
  }

  .border-none{
    border: none !important;
  }

  .color-onyx{
    color: #414142 !important;
  }

  .color-onyx-bold{
    color: #414142 !important;
    font-weight: bold !important;
  }

  .bg-onyx{
    background-color: #414142;
    color: #FFFFFF;
  }

  .bg-onyx-outline{
    border: 1px solid #414142 !important;
    color: #414142 !important;
  }

  .color-gray{
    color: #BDBDBD;
  }

  .color-blue{
    color: #007bff!important;;
  }

  .bg-dark-gray{
    background-color: #999999;
  }

  .d-none{
    display: none !important;
  }

  .text-danger {
    color: #ff2626;
  }
`;
