import React, { createContext, useCallback, useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import PropTypes from 'prop-types';

import {
  Container,
  HeaderContent,
  BodyContent,
  FooterContent,
  Content,
} from './styles';

const ModalContext = createContext({
  onHide() {},
  show: false,
});

function ModalProvider({ children, onHide, show }) {
  return (
    <ModalContext.Provider value={{ onHide, show }}>
      {children}
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within an ModalProvider');
  }

  return context;
}

function Header({ children, className }) {
  const { onHide } = useModal();

  return (
    <HeaderContent className={`flex justify-between items-start ${className}`}>
      <div className="w-full">{children}</div>
      <button type="button" className="bg-transparent" onClick={onHide}>
        <AiOutlineClose size={25} color="#000000" />
      </button>
    </HeaderContent>
  );
}

function Body({ children, className }) {
  return <BodyContent className={className}>{children}</BodyContent>;
}

function Footer({ children, className }) {
  return <FooterContent className={className}>{children}</FooterContent>;
}

function ModalContent({ children, className }) {
  const { show, onHide } = useModal();

  const handleClick = useCallback(
    e => {
      if (!e.target.classList.contains('modalContent')) {
        if (!e.target.closest('.modalContent')) {
          onHide();
        }
      }
    },
    [onHide],
  );

  return (
    <Container onClick={handleClick} className={className} show={show}>
      <Content className="modalContent px-5 py-10 md:p-10">{children}</Content>
    </Container>
  );
}

export function Modal({ children, show, onHide, className }) {
  return (
    <ModalProvider onHide={onHide} show={show}>
      <ModalContent className={className}>{children}</ModalContent>
    </ModalProvider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
};

ModalProvider.defaultProps = {
  onHide: () => {},
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
};

Body.defaultProps = {
  className: '',
};

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

ModalContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
};

ModalContent.defaultProps = {
  className: '',
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onHide: PropTypes.func,
};

Modal.defaultProps = {
  className: '',
  onHide: () => {},
};
