import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  background-color: ${props => lighten(0.2, `${props.color}44`)};
  border: 1px solid ${props => props.color};
  width: 41px;
  height: 41px;

  p {
    margin-top: 4px;
    color: ${props => props.color} !important;
    text-align: center;
  }
`;
