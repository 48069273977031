import styled from 'styled-components';
import { darken } from 'polished';

import { Modal as ModalComponent } from '~/components/Modal';

export const Search = styled.div`
  border-radius: 100px;
  border: 1px solid #dddddd !important;
  background-color: #ffffff;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: #ffffff;
  }
`;

export const TableContent = styled.div`
  border-radius: 10px;
  background-color: transparent;
  z-index: 0;

  .legend {
    button {
      color: #62666b;

      ::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 2px;
        display: inline-block;
        margin-right: 8px;
      }
    }

    button:first-child {
      ::before {
        background: #ffbe21;
      }
    }

    button:last-child {
      ::before {
        background: #9ddef2;
      }
    }

    button.active {
      border-bottom: 2px solid #a1c64d !important;
    }
  }

  .menu-table {
    z-index: 1000;
    top: 15px;
    left: 15px;
    right: 15px;

    .search {
      padding: 1px;
      border-radius: 100px;

      input {
        margin: 10px 0;
        padding: 0 10px;
        border: none;
        border-radius: 100px;
        background-color: #ffffff;
      }
    }

    .divisor {
      height: 1px;
      background-color: #e1e1e1;
    }

    .inputDate {
      border: 1px solid rgba(130, 130, 130, 1);
      background-color: transparent;
      color: rgba(130, 130, 130, 1) !important;
      padding: 5px 10px;
      border-radius: 100px;
      text-align: center;
    }
  }

  div.tableData {
    .rdt_TableHeader {
      display: ${props => (props.showHeader ? 'block' : 'none')};
    }

    .rdt_TableRow:nth-child(odd) {
      background-color: #ffffff;
    }

    .rdt_TableRow:nth-child(even) {
      background-color: #f6f6f6;
    }

    .rdt_TableRow {
      border: none !important;
      padding: 15px 0 10px 0;
      cursor: ${props => (props.rowIsClickable ? 'pointer' : 'default')};
      transition-duration: 0.3s;

      div {
        transition-duration: 0.3s;
      }

      :hover {
        background-color: ${darken(0.05, '#f6f6f6')};

        > div {
          background-color: ${darken(0.05, '#f6f6f6')};
        }
      }
    }
  }
`;

export const Pagination = styled.div`
  p,
  button {
    color: #8c8c8c;
  }

  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-weight: bold;

    :first-child,
    :last-child {
      width: auto;
      border-radius: 5px;
    }
  }

  button.active,
  button:hover {
    background: #3f3f3f !important;
    color: #dadada;
  }
`;

export const Modal = styled(ModalComponent)`
  label {
    color: #8a8b8b;
    font-weight: 500;
    font-size: 18px;
  }

  .state-card {
    width: 65px;
    height: 35px;
    background: #f5f5f5;
    border-radius: 100px;
    margin-right: 10px;

    span {
      color: #9c9c9c;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .input {
    border: none;
    border-bottom: 1.5px solid #e6e7e8;
    border-radius: 0;

    input {
      color: #8c8c8c !important;
    }
  }

  .btn-cancel,
  .btn-submit {
    border: 2px solid #a1c64d;
    font-weight: bold;
  }

  .btn-cancel {
    color: #a1c64d;
    background: transparent;
  }

  .btn-submit {
    color: #ffffff;
    background: #a1c64d;
  }
`;
