import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

export const Item = styled.div`
  ${props =>
    props.active
      ? css`
          p {
            color: #8a8b8b;
          }
          .step {
            color: #414142;
          }
        `
      : css`
          p {
            color: #bdbdbd;
          }
        `}
`;

export const Progress = styled.div`
  height: 8px;
  background: #f4f4f4;
  border-radius: 100px;
  transition-duration: 0.2s;

  ::before {
    content: '${props => props.percent}%';
    position: absolute;
    top: -40px;
    left: calc(${props => props.percent}% - 25px);
    background: #f4f4f4;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
    z-index: 100;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.2s;
  }

  ::after {
    content: '';
    position: absolute;
    background-color: #a1c64d;
    border-radius: 100px;
    width: ${props => props.percent}%;
    height: 8px;
    top: 0;
    left: 0;
    transition-duration: 0.2s;
  }

  :hover {
    ::before {
      opacity: 1;
      visibility: visible;
    }
  }
`;
