import styled, { keyframes } from 'styled-components';
import { lighten, darken } from 'polished';

import signInBackgroung from '~/assets/banners/sign-in-background.png';

export const Container = styled.div`
  background-color: #ffffff;
  min-height: 100vh;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  h2 {
    color: #b8b9ba;
  }

  p {
    color: #606060;
  }

  .font-weigth-500 {
    font-weight: 500 !important;
  }

  .button-login {
    background-color: #414142;
    color: #ffffff;
    text-decoration: none;
    border: none;

    :hover {
      background-color: ${lighten(0.1, '#414142')};
      color: ${lighten(0.1, '#FFFFFF')};
      transition-duration: 0.5s;
    }
  }

  .small {
    color: #828282;
  }

  form {
    margin: 80px 0;
    width: 450px;

    > div:not(.google) {
      > div {
        padding: 6.5px 10px;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #e6e7e8;
      }
    }

    .google {
      button {
        background-color: transparent !important;
      }
    }

    input {
      width: 100%;
      height: 40px;
      color: #606060 !important;
    }

    .button-show {
      height: 56px !important;
      top: -8px !important;
    }

    input[id='password'] {
      width: 81%;
    }

    a {
      color: #a1c64d;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: ${darken(0.1, '#A1C64D')};
      }
    }

    @media screen and (max-height: 700px) {
      margin: 20px 0;
    }

    @media screen and (max-width: 450px) {
      input[id='password'] {
        width: 72%;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    form {
      margin: 30px 0px 0px 0px;
      width: 390px;
      input {
      }
    }
  }

  @media screen and (max-width: 1199px) {
    form {
      width: 100%;
      margin: 25px 0px 0px 0px;
    }

    .content {
      position: relative;
      z-index: 1;
      opacity: 0.8;

      a {
        color: rgba(203, 204, 206, 1);
      }
    }
  }

  @media screen and (max-width: 991px) {
    form {
      margin: 40px 0px 0px 0px;
    }
  }
`;

export const Background = styled.div`
  filter: drop-shadow(-8px 0px 14px rgba(0, 0, 0, 0.25));
  background-image: url(${signInBackgroung});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;

  .shade {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  .content {
    position: relative;
    z-index: 1;
    opacity: 0.8;

    a {
      color: rgba(203, 204, 206, 1);
    }
  }
`;
