import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { BsImage } from 'react-icons/bs';

import { Container } from './styles';

export default function ButtonAttachment({
  title,
  icon,
  iconColor,
  className,
  onClick,
  approved,
  statusColor,
  downloadable,
  fileUrl,
}) {
  const handleClick = useCallback(() => {
    if (downloadable) {
      window.open(fileUrl, '_blank').focus();
    } else {
      onClick();
    }
  }, [downloadable, fileUrl, onClick]);

  return (
    <Container
      type="button"
      onClick={handleClick}
      className={`w-full text-left ${className}`}
      approved={approved}
      statusColor={statusColor}
    >
      <div className="flex items-start">
        {icon === 'image' ? (
          <BsImage size={30} color={iconColor} />
        ) : (
          <AiOutlinePaperClip size={30} color={iconColor} />
        )}
        <div className="ml-4">
          <h3>{title}</h3>
          {approved.length > 0 ? (
            <p
              className={`mt-3 ${approved === 'Aprovado' ? 'approved' : ''} ${
                approved === 'Reprovado' ? 'disapproved' : ''
              }`}
            >
              {approved === 'Aprovado'
                ? 'Documento aprovado'
                : 'Necessita correção'}
            </p>
          ) : (
            <p className="mt-3">{downloadable ? 'Baixar' : 'Abrir'}</p>
          )}
        </div>
      </div>
    </Container>
  );
}

ButtonAttachment.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  approved: PropTypes.string,
  statusColor: PropTypes.string,
  downloadable: PropTypes.bool,
  fileUrl: PropTypes.string,
};

ButtonAttachment.defaultProps = {
  iconColor: '#E6E7E8',
  className: '',
  approved: '',
  statusColor: '#00000000',
  onClick: () => {},
  downloadable: false,
  fileUrl: '',
};
