import styled from 'styled-components';
import { Modal as ModalComponent } from '~/components/Modal';

export const Modal = styled(ModalComponent)`
  .modalContent {
    width: 1000px;
  }

  .hidden {
    display: none !important;
  }

  .detail {
    background: #c4e1b6;
    border-radius: 20px;
  }

  .content {
    background: #dff6d4;
    border-radius: 20px;
  }

  .box {
    background: #ffffff;
    border: 1px solid #f7ebeb;
    border-radius: 16px;
  }

  label {
    color: #8a8b8b;
    font-size: 18px;
    font-weight: 500;
  }

  .pdf {
    background: #ffffff;
    border: 1px solid #f7ebeb;
    border-radius: 16px;
  }

  .input {
    border: none !important;
    border-bottom: 1px solid #e6e7e8 !important;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;

    input {
      color: #8a8b8b !important;
      padding: 0;

      ::placeholder {
        color: #e6e7e8;
      }
    }
  }

  small {
    height: 20px;
  }

  .date-box {
    border-bottom: 1px solid #e6e7e8 !important;

    .input-date {
      border: none !important;
      border-radius: 0;
      padding-left: 0 !important;
      padding-right: 0 !important;

      input {
        color: #8a8b8b !important;
        padding: 0;

        ::placeholder {
          color: #e6e7e8;
        }
      }
    }
  }

  .signature-box {
    background: #f9f9f9;
    border-radius: 20px;
  }

  .spacer {
    width: 16px;
  }

  .btn-cancel {
    border: 2px solid #a1c64d;
    background-color: transparent;
    color: #a1c64d;
  }

  .btn-submit {
    border: 2px solid #a1c64d;
    background: #a1c64d;
    color: #ffffff;
  }

  .search-user {
    border: 1px solid #dddddd;

    input {
      border: none;

      :focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }

  .error {
    color: #c53030;
  }
`;

export const ButtonUser = styled.button`
  transition-duration: 0.2s;
  border-radius: 16px;
  background: ${props => (props.selected ? '#f5f5f5' : 'transparent')};

  img {
    width: 40px;
    height: 40px;
  }

  :hover {
    background: #f5f5f5;
  }
`;
