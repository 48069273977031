import React, { useCallback, useState, useEffect } from 'react';
import { BsChatDots, BsImageFill } from 'react-icons/bs';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoSend } from 'react-icons/io5';
import { BiSmile } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useValidError } from '~/hooks/ValidError';
import api from '~/services/api';

import { Container, ChatForm, Content } from './styles';
import CircleName from '~/components/CircleName';
import Textarea from '~/components/Textarea';

export default function Chat() {
  const { user } = useAuth();
  const { validError } = useValidError();
  const params = useParams();
  const [rows, setRows] = useState(1);
  const [active, setActive] = useState(false);
  const [chatId, setChatId] = useState(0);
  const [osId, setOsId] = useState(0);
  const [constructorId, setConstructorId] = useState(0);
  const [constructor, setConstructor] = useState({});
  const [hasConstructor, setHasConstructor] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(false);
  const [page, setPage] = useState(1);
  const [connectionEstablished, setConnectionEstablished] = useState(false);

  useEffect(() => {
    const bodyChat = document.querySelector('.bodyChat');
    if (bodyChat) {
      bodyChat.scrollTo({ top: bodyChat.scrollHeight, behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    api
      .get(`correspondents/oss/${params.slug}`)
      .then(async responseOS => {
        const response = await api.get(
          `correspondents/oss/chats/${params.slug}/${user.id}`,
        );

        if (Object.keys(response.data).length > 0) {
          const data = response.data.messages.map((message, index) => {
            let last = true;
            if (response.data.messages[index + 1]) {
              last =
                message.usuario_id !==
                response.data.messages[index + 1].usuario_id;
            }

            return {
              id: message._id,
              from: message.usuario_id,
              last,
              content: message.content,
            };
          });

          setChatId(response.data.id);
          setMessages(data);
        }

        setOsId(responseOS.data.id);
        if (responseOS.data.construtor_id) {
          setConstructorId(responseOS.data.construtor_id);
          setHasConstructor(!!responseOS.data.construtor_id);
          const nameParts = responseOS.data.construtor.name.split(' ');
          const initials = `${nameParts[0][0]}${
            nameParts[nameParts.length - 1][0]
          }`.toUpperCase();
          const data = {
            id: responseOS.data.construtor.id,
            name: `${nameParts[0]} ${nameParts[1]}`,
            initials,
          };
          setConstructor(data);
        }
      })
      .catch(error => {
        validError(error);
      });
  }, [params, user.id, validError]);

  useEffect(() => {
    if (chatId && !connectionEstablished) {
      const socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/chat`);

      socket.onopen = () => {
        const data = {
          user_id: user.id,
          chat_id: chatId,
        };

        socket.send(JSON.stringify(data));
        setConnectionEstablished(true);
      };

      socket.onmessage = event => {
        const data = JSON.parse(event.data);
        const message = {
          id: data._id,
          from: data.user_id,
          last: true,
          content: data.message,
        };

        if (!active) {
          setNewMessage(true);
        }

        setMessages(state => {
          const newState = state.slice();
          if (newState[0] && newState[0].from === data.user_id) {
            newState[0].last = false;
          }
          newState.unshift(message);
          return newState;
        });
      };

      socket.onerror = () => {
        console.log('Não foi possivel conectar no chat');
      };
    }
  }, [active, chatId, connectionEstablished, user.id]);

  const handleClick = useCallback(async () => {
    try {
      if (!chatId) {
        const formDataChat = {
          os_id: osId,
        };
        const response = await api.post('oss/chats', formDataChat);
        if (response.data) {
          setChatId(response.data.id);
          const formDataChatMyUser = {
            chat_id: response.data.id,
            usuario_id: user.id,
            usuario_grupoacesso_id: 1,
          };
          api.post('oss/chats/users', formDataChatMyUser);

          const formDataChatYourUser = {
            chat_id: response.data.id,
            usuario_id: constructorId,
            usuario_grupoacesso_id: 1,
          };
          api.post('oss/chats/users', formDataChatYourUser);
        }
      }
      setNewMessage(false);
      setActive(state => !state);
    } catch (error) {
      validError(error);
    }
  }, [chatId, constructorId, osId, user.id, validError]);

  const handleClose = useCallback(() => {
    setActive(false);
  }, []);

  const handleChange = useCallback(e => {
    const { value, offsetWidth } = e.target;
    const auxText = document.getElementById('auxText');
    auxText.innerHTML = value;
    const rowsData = Math.trunc(auxText.offsetWidth / (offsetWidth - 10));
    const lines = value.split('\n').length;
    if (rowsData < 3 && lines < 4) {
      if (rowsData + 1 > lines) {
        setRows(rowsData + 1);
      } else {
        setRows(lines);
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        const formData = {
          chat_usuario_id: constructorId,
          chat_id: chatId,
          usuario_id: user.id,
          content: data.message,
        };

        api.post('correspondents/oss/chats/messages', formData);

        const message = {
          id: `${new Date().getTime()}`,
          from: user.id,
          last: true,
          content: data.message,
        };

        setMessages(state => {
          const newState = state.slice();
          if (newState[0] && newState[0].from === user.id) {
            newState[0].last = false;
          }
          newState.unshift(message);
          return newState;
        });

        const element = document.getElementById('message');
        if (element) {
          element.value = '';
        }
      } catch (error) {
        validError(error);
      }
    },
    [chatId, constructorId, user.id, validError],
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (e.target.value.length > 0) {
          const data = { message: e.target.value };
          e.target.value = '';
          setRows(1);
          handleSubmit(data);
        }
      }
    },
    [handleSubmit],
  );

  const loadOldMessages = useCallback(async () => {
    try {
      const newPage = page + 1;
      const response = await api.get(
        `correspondents/oss/chats/messages/${chatId}`,
        {
          params: {
            page: newPage,
          },
        },
      );

      if (response.data && response.data.data.length > 0) {
        const data = response.data.data.map((message, index) => {
          let last = true;
          if (response.data.data[index + 1]) {
            last =
              message.usuario_id !== response.data.data[index + 1].usuario_id;
          }

          return {
            id: message._id,
            from: message.usuario_id,
            last,
            content: message.content,
          };
        });

        setMessages(state => [...state, ...data]);
        setPage(newPage);
      }
    } catch (error) {
      validError(error);
    }
  }, [page, chatId, validError]);

  return (
    <Container className="fixed">
      {hasConstructor && (
        <div className="flex flex-col items-end">
          <ChatForm onSubmit={handleSubmit} active={active} className="px-3">
            <div className="box flex flex-col justify-between">
              <div className="header flex p-3">
                <div className="flex w-full">
                  <CircleName color="#617198" name={constructor.initials} />
                  <div className="ml-2">
                    <h3>{constructor.name}</h3>
                    <p>OS: {osId}</p>
                  </div>
                </div>
                <button
                  type="button"
                  className="border-none bg-transparent"
                  onClick={handleClose}
                >
                  <IoIosCloseCircleOutline size={32} color="#A1C64D" />
                </button>
              </div>
              <Content
                className="bodyChat p-3"
                flipped
                scrollLoadThreshold={100}
                onInfiniteLoad={loadOldMessages}
                key={message => message.id}
              >
                {messages.map(message => (
                  <div
                    key={message.id}
                    className={`flex flex-col ${message.last ? 'last' : ''} ${
                      message.from === user.id
                        ? 'my-message ml-auto'
                        : 'your-message mr-auto'
                    }`}
                  >
                    <div className="px-3 py-2">
                      <p>{message.content}</p>
                    </div>
                  </div>
                ))}
              </Content>
              <div className="footer flex items-center">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="ml-2 mr-1 border-none bg-transparent"
                  >
                    <BsImageFill size={19} color="#B0B6C3" />
                  </button>
                  <button
                    type="button"
                    className="ml-1 mr-2 border-none bg-transparent"
                  >
                    <BiSmile size={19} color="#B0B6C3" />
                  </button>
                </div>
                <div className="py-3 w-full">
                  <Textarea
                    id="message"
                    name="message"
                    className="border-none"
                    placeholder="Digite aqui"
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    rows={rows}
                  />
                  <div id="auxText" className="absolute" />
                </div>
                <button
                  type="submit"
                  className="mx-2 border-none bg-transparent"
                >
                  <IoSend size={19} color="#A1C64D" />
                </button>
              </div>
            </div>
          </ChatForm>
          <button
            type="button"
            className={`flex flex-col justify-center items-center ${
              newMessage ? 'notification' : ''
            }`}
            onClick={handleClick}
          >
            <BsChatDots size={23} color="#FFFFFF" />
            Chat
          </button>
        </div>
      )}
    </Container>
  );
}
