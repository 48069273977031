import React from 'react';
import PropTypes from 'prop-types';

export default function AuthLayout({ children }) {
  return <>{children}</>;
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
