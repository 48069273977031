import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'tailwindcss/dist/tailwind.min.css';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';
import Routes from './routes';

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes />
        <GlobalStyles />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
