import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, CircleFill } from './styles';
import Input from '~/components/Input';

export default function InputCheckbox({ name, options, className, onChange }) {
  const [optionsData, setOptionsData] = useState([]);
  const [optionSelected, setOptionSelected] = useState('');

  useEffect(() => {
    setOptionsData(options);
    const selected = options.find(option => option.selected);
    if (selected) {
      setOptionSelected(selected.value);
    } else {
      setOptionSelected('');
    }
  }, [options]);

  const handleChange = useCallback(
    e => {
      const newOptions = optionsData.slice();
      const optionSelectedIndex = newOptions.findIndex(
        option => option.selected,
      );
      const optionIndex = newOptions.findIndex(
        option => option.id === e.target.id,
      );
      if (optionSelectedIndex >= 0) {
        newOptions[optionSelectedIndex].selected = false;
      }
      if (optionIndex >= 0) {
        newOptions[optionIndex].selected = true;
        setOptionSelected(newOptions[optionIndex].value);
      }
      setOptionsData(newOptions);
      onChange(newOptions);
    },
    [onChange, optionsData],
  );

  return (
    <>
      {optionsData.map(option => (
        <label
          key={option.id}
          className="flex items-center mb-3 lg:mb-0 md:mr-5"
          htmlFor={option.id}
        >
          <Container className={`mr-3 ${className}`} checked={option.selected}>
            {option.selected && <CircleFill />}
            <input
              type="radio"
              id={option.id}
              name={`${name}-radio`}
              className="d-none"
              onChange={handleChange}
              value={option.value}
              checked={optionSelected === option.value}
            />
          </Container>
          {option.label}
        </label>
      ))}
      <Input name={name} value={optionSelected} className="d-none" />
    </>
  );
}

InputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

InputCheckbox.defaultProps = {
  className: '',
  onChange: () => {},
};
