import styled from 'styled-components';

import { darken } from 'polished';

export const Container = styled.div`
  h1 {
    font-size: 36px;
    color: #414142;
    font-weight: 600;
  }
  .total-notifications {
    color: #fff;
    background-color: #a1c64d;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
  }

  .notifications {
    color: #fff;
    background-color: #a1c64d;
    font-size: 13px;
  }
`;

export const Notifications = styled.div`
  h2 {
    color: #414142;
  }
  h3 {
    color: #b8b9ba;
  }
  p {
    color: #8695a0;
  }

  hr {
    border-top: 1px solid #eaeaea;
  }
  .height-notifications {
    height: 400px;
  }

  .notifications-hover:hover {
    background-color: ${darken(0.1, '#fff')};
  }
`;
