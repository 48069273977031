import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chat from '~/components/Chat';

export default function DefaultLayout({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {children}
      <Chat />
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
