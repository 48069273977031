import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useField } from '@unform/core';
import TextInputMask from 'react-masked-text';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function InputMask({
  kind,
  name,
  onChange,
  onFocus,
  onBlur,
  value,
  placeholder,
  id,
  className,
  options,
  required,
}) {
  const maskRef = useRef(null);
  const [newValue, setNewValue] = useState(undefined);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: maskRef.current,
      path: 'props.value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const handleChange = useCallback(
    e => {
      if (onChange && maskRef.current) {
        const data = {
          target: {
            name: maskRef.current.props.name,
            value: e,
          },
        };
        onChange(data);
      }
      if (kind !== 'datetime') {
        setNewValue(e);
      }
    },
    [onChange, kind],
  );

  const handleFocus = useCallback(
    e => {
      setIsFocuses(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    e => {
      setIsFocuses(false);
      if (onBlur) {
        onBlur(e);
      }
      setIsFilled(!!e.target.value);
    },
    [onBlur],
  );

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <TextInputMask
          ref={maskRef}
          kind={kind}
          options={options}
          name={name}
          placeholder={placeholder}
          required={required}
          value={newValue}
          onChangeText={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          id={id}
        />
      </Container>
      {error && <span className="small text-danger error">{error}</span>}
    </>
  );
}

InputMask.propTypes = {
  kind: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.shape({
    mask: PropTypes.string,
    format: PropTypes.string,
    unit: PropTypes.string,
    separator: PropTypes.string,
    delimiter: PropTypes.string,
  }),
  required: PropTypes.bool,
};

InputMask.defaultProps = {
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  value: '',
  placeholder: '',
  id: '',
  className: '',
  options: {
    mask: '',
    format: '',
    unit: '',
    separator: '',
    delimiter: '',
  },
  required: '',
};
