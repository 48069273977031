import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { parseISO, formatDistanceStrict } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import ReactChatView from 'react-chatview';
import { RiNotification4Fill } from 'react-icons/ri';
import { CgClose } from 'react-icons/cg';

import { useAuth } from '~/hooks/Auth';
import { useValidError } from '~/hooks/ValidError';
import api from '~/services/api';
import pushNotification from '~/utils/pushNotification';

import { Container, Notifications, Notification, Avatar } from './styles';
import CircleName from '~/components/CircleName';

import logo from '~/assets/logos/logo.svg';

export default function MiniProfile() {
  const { user } = useAuth();
  const { validError } = useValidError();
  const [connectionEstablished, setConnectionEstablished] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotification] = useState(false);
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    api.get(`correspondents/notifications/${user.id}`).then(response => {
      const data = response.data.data.map(notification => {
        const date = formatDistanceStrict(
          parseISO(notification.created_at),
          new Date(),
          {
            includeSeconds: true,
            addSuffix: true,
            locale: ptBr,
          },
        );
        return {
          id: notification._id,
          content: notification.content,
          url: notification.url,
          read: notification.read,
          date,
        };
      });

      const checkNotification = data.find(notification => !notification.read);

      if (checkNotification) {
        setNewNotification(true);
      }

      setNotifications(data);
    });

    if (!connectionEstablished) {
      const socket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}/notification`,
      );

      socket.onopen = () => {
        const data = {
          user_id: user.id,
        };

        socket.send(JSON.stringify(data));
        setConnectionEstablished(true);
      };

      socket.onmessage = event => {
        const data = JSON.parse(event.data);
        const date = formatDistanceStrict(
          parseISO(data.created_at),
          new Date(),
          {
            includeSeconds: true,
            addSuffix: true,
            locale: ptBr,
          },
        );

        const notification = {
          id: data._id,
          content: data.message,
          url: data.url,
          read: false,
          date,
        };

        if (!notificationOpened) {
          pushNotification(`${notification.content} ${notification.url || ''}`);
          setNewNotification(true);
        } else {
          api.put(`correspondents/notifications/${notification.id}`, {
            read: true,
          });
        }
        setNotifications(state => [notification, ...state]);
      };

      socket.onerror = () => {
        console.log('Não foi possivel conectar nas notificações');
      };
    }
  }, [notificationOpened, connectionEstablished, user.id, validError]);

  const userData = useMemo(() => {
    const nameParts = user.name.split(' ');
    return {
      id: user.id,
      color: '#617198',
      name: `${nameParts[0]} ${nameParts[1]}`,
      initials: `${nameParts[0][0]}${
        nameParts[nameParts.length - 1][0]
      }`.toUpperCase(),
    };
  }, [user]);

  const handleClick = useCallback(() => {
    setNewNotification(false);
    setNotificationOpened(state => !state);
    notifications
      .filter(notification => !notification.read)
      .forEach(notification => {
        api.put(`correspondents/notifications/${notification.id}`, {
          read: true,
        });
      });
  }, [notifications]);

  const loadOldNotifications = useCallback(async () => {
    console.log('AQUI');
    try {
      const newPage = page + 1;
      const response = await api.get(
        `correspondents/notifications/${user.id}`,
        {
          params: {
            page: newPage,
          },
        },
      );

      if (response.data && response.data.data.length > 0) {
        const data = response.data.data.map(notification => {
          const date = formatDistanceStrict(
            parseISO(notification.created_at),
            new Date(),
            {
              includeSeconds: true,
              addSuffix: true,
              locale: ptBr,
            },
          );
          return {
            id: notification._id,
            content: notification.content,
            url: notification.url,
            read: notification.read,
            date,
          };
        });
        setNotifications(state => [...state, ...data]);
        setPage(newPage);
      }
    } catch (error) {
      validError(error);
    }
  }, [page, user.id, validError]);

  return (
    <Container className="w-full hidden md:flex flex-row flex-wrap items-center">
      <div className="w-2/12 flex items-center justify-center">
        <button type="button" onClick={handleClick} className="relative">
          {newNotifications && <div className="alert" />}
          <IoMdNotificationsOutline size={30} color="#BDBDBD" />
        </button>
      </div>
      <div className="w-2/12">
        <CircleName name={userData.initials} color={userData.color} />
      </div>
      <div className="w-8/12 pl-2">
        <p className="font-normal color-onyx">{userData.name}</p>
        <p className="font-normal color-gray">ID: {userData.id}</p>
      </div>
      <div className="w-full">
        <Notifications
          opened={notificationOpened}
          className="fixed flex justify-end"
        >
          <button type="button" onClick={() => setNotificationOpened(false)} />
          <ReactChatView
            className="bg-white p-6"
            scrollLoadThreshold={100}
            onInfiniteLoad={loadOldNotifications}
          >
            <button
              type="button"
              className="block border-0 bg-transparent ml-auto mb-4"
              onClick={() => setNotificationOpened(false)}
            >
              <CgClose size={20} color="#949494" />
            </button>
            <h4>
              Notificações
              <span className="inline-block ml-2">
                <RiNotification4Fill size={23} color="#414142" />
              </span>
            </h4>
            <div className="mt-5">
              {notifications.map(notification => (
                <Notification key={notification.id} className="p-5">
                  <small className="text-gray small mb-1 text-right block">
                    {notification.date}
                  </small>
                  <div className="flex">
                    <Avatar>
                      <img src={logo} alt="logo" />
                    </Avatar>
                    <div>
                      {/* <h6 className="my-1">Novo curso lançado!</h6> */}
                      <p className="text-gray small py-2 mt-0 mb-3">
                        {notification.content}
                      </p>
                      {!!notification.url && (
                        <a
                          href={notification.url}
                          target="_blank"
                          className="btn btn-primary px-6 py-1 mr-auto"
                          rel="noreferrer"
                        >
                          Ir
                        </a>
                      )}
                    </div>
                  </div>
                </Notification>
              ))}
            </div>
          </ReactChatView>
        </Notifications>
      </div>
    </Container>
  );
}
