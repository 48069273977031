import styled, { css } from 'styled-components';

export const Container = styled.div`
  .btn-group {
    background: #ffffff;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid ${props => props.statusColor};

    h3 {
      color: #8d8d8e;
    }

    p {
      color: #414142;
      font-weight: 600;
      text-decoration: underline;
    }

    button {
      padding: 15px 20px;
      background: none;
      border: none;
    }
  }

  div.alert-box {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px 30px;

    ${props =>
      props.approved.length > 0 &&
      css`
        border: 2px solid
          ${props.approved === 'Aprovado' ? '#A1C64D' : '#ff2626'};
      `}
  }

  @media screen and (max-width: 991px) {
    button {
      padding: 20px;
    }
  }
`;
