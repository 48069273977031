import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Loading({ active }) {
  return (
    <>
      {active && (
        <Container className="loading-box">
          <div className="loader" role="status" />
        </Container>
      )}
    </>
  );
}

Loading.propTypes = {
  active: PropTypes.bool,
};

Loading.defaultProps = {
  active: false,
};
