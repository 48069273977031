/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { Form } from '@unform/web';
import { useParams, useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import JSZip from 'jszip';

import { useAuth } from '~/hooks/Auth';
import { useValidError } from '~/hooks/ValidError';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { randomColor } from '~/utils/colorGenerator';

import {
  Container,
  Background,
  AnimationContainer,
  Step,
  Modal,
  Box,
} from './styles';
import CircleName from '~/components/CircleName';
import ButtonAttachment from '~/components/ButtonAttachment';
import SubButtonAttachment from '~/components/SubButtonAttachment';
import Input from '~/components/Input';
import InputRadio from '~/components/InputRadio';
import Loading from '~/components/Loading';

import logo from '~/assets/logos/logo-login.png';

const zip = new JSZip();

export default function Documents() {
  const { user } = useAuth();
  const { validError } = useValidError();
  const params = useParams();
  const history = useHistory();
  const formRef = useRef(null);

  const [analisisDocumentsGroup, setAnalisisDocumentsGroup] = useState([]);
  const [analisisProccessDocumentsGroup, setAnalisisProccessDocumentsGroup] =
    useState([]);
  const [analisisExtrasDocumentsGroup, setAnalisisExtrasDocumentsGroup] =
    useState([]);
  const [typeSelected, setTypeSelected] = useState('');
  const [documents, setDocuments] = useState([]);
  const [fileSelected, setFileSelected] = useState({});
  const [documentsParticipants, setDocumentsParticipants] = useState([]);
  const [step, setStep] = useState(0);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [show, setShow] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [sallers, setSallers] = useState([]);
  const [participantSelected, setParticipantSelected] = useState({});
  const [sallerSelected, setSallerSelected] = useState({});
  const [observation, setObservation] = useState('');
  const [clientData, setClientData] = useState({});
  const [url, setUrl] = useState('');

  const compare = useCallback((a, b) => {
    if (a.tipo_arquivo.id < b.tipo_arquivo.id) {
      return -1;
    }
    if (a.tipo_arquivo.id > b.tipo_arquivo.id) {
      return 1;
    }
    return 0;
  }, []);

  useEffect(() => {
    switch (params.page) {
      case 'verificar-documentos':
        setStep(1);
        break;
      case 'analise-de-abertura-de-processo':
        setStep(2);
        break;
      case 'resultado-da-conformidade':
        setStep(3);
        break;
      default:
        setStep(4);
        break;
    }
  }, [params.page]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`correspondents/oss/files/${params.slug}`)
      .then(async response => {
        const sortedData = response.data.sort(compare);

        const analisisData = [];
        const analisisProccessData = [];
        const analisisExtraData = [];
        const sallersData = [];
        const participantsData = [];

        if (step !== 2) {
          const responseParticipants = await api.get(
            `correspondents/oss/involved/${params.slug}`,
          );

          if (responseParticipants.data.client) {
            const clientNameParts =
              responseParticipants.data.client.nome.split(' ');
            const client = {
              id: responseParticipants.data.client.id,
              type: 'client',
              color: randomColor(),
              name: `${clientNameParts[0][0]}${
                clientNameParts[clientNameParts.length - 1][0]
              }`.toUpperCase(),
            };
            participantsData.push(client);
          }

          if (responseParticipants.data.spouse) {
            const spouseNameParts =
              responseParticipants.data.spouse.nome.split(' ');
            const spouse = {
              id: responseParticipants.data.spouse.id,
              type: 'spouse',
              color: randomColor(),
              name: `${spouseNameParts[0][0]}${
                spouseNameParts[spouseNameParts.length - 1][0]
              }`.toUpperCase(),
            };
            participantsData.push(spouse);
          }

          if (responseParticipants.data.participants.length > 0) {
            responseParticipants.data.participants.forEach(participant => {
              const participantNameParts = participant.nome.split(' ');
              const participantData = {
                id: participant.id,
                type: 'participant',
                color: randomColor(),
                name: `${participantNameParts[0][0]}${
                  participantNameParts[participantNameParts.length - 1][0]
                }`.toUpperCase(),
              };
              participantsData.push(participantData);
            });
          }

          sortedData.forEach(fileData => {
            let type = 'others';
            if (fileData.mimeType) {
              if (fileData.mimeType.match('pdf')) {
                type = 'pdf';
              } else if (fileData.mimeType.match('image')) {
                type = 'image';
              }
            }

            const file = {
              id: fileData.id,
              name: fileData.nome,
              observation: fileData.obsCorresp,
              observationUser: fileData.obsCliente,
              url: fileData.file_url,
              type,
              result: fileData.resultado ? fileData.resultado.resultado : '',
              downloadable:
                fileData.documento_extra || !fileData.precisa_validacao,
              vendedor: fileData.vendedor,
              extraDocument: fileData.documento_extra,
              needValidation: fileData.precisa_validacao,
            };

            const index = `group_${fileData.tipo_arquivo.grupo}`;
            if (index === 'group_1') {
              if (analisisData[index]) {
                if (analisisData[index][[fileData.tipo_arquivo.tipo_arquivo]]) {
                  analisisData[index][
                    [fileData.tipo_arquivo.tipo_arquivo]
                  ].push(file);
                } else {
                  analisisData[index][fileData.tipo_arquivo.tipo_arquivo] = [
                    file,
                  ];
                }
              } else {
                analisisData[index] = [];
                analisisData[index][fileData.tipo_arquivo.tipo_arquivo] = [
                  file,
                ];
              }
            }
          });

          Object.keys(analisisData).forEach(groupKey => {
            Object.keys(analisisData[groupKey]).forEach(typeKey => {
              const documentsData = {
                data: analisisData[groupKey][typeKey],
                statusColor: '#00000000',
              };
              let files = analisisData[groupKey][typeKey].filter(
                file => file.result === 'Reprovado',
              );
              if (files.length === 0) {
                files = analisisData[groupKey][typeKey].filter(
                  file => file.result === 'Aprovado',
                );
                if (files.length > 0) {
                  if (files.length === analisisData[groupKey][typeKey].length) {
                    documentsData.statusColor = '#A1C64D';
                  } else {
                    documentsData.statusColor = '#FFC700';
                  }
                }
              } else {
                documentsData.statusColor = '#EB0808';
              }

              analisisData[groupKey][typeKey] = documentsData;
            });
          });
        }

        if (step !== 1) {
          sortedData.forEach(fileData => {
            let type = 'others';
            if (fileData.mimeType) {
              if (fileData.mimeType.match('pdf')) {
                type = 'pdf';
              } else if (fileData.mimeType.match('image')) {
                type = 'image';
              }
            }

            const file = {
              id: fileData.id,
              name: fileData.nome,
              observation: fileData.obsCorresp,
              observationUser: fileData.obsCliente,
              url: fileData.file_url,
              type,
              result: fileData.resultado ? fileData.resultado.resultado : '',
              downloadable:
                fileData.documento_extra || !fileData.precisa_validacao,
              vendedor: fileData.vendedor,
              extraDocument: fileData.documento_extra,
              needValidation: fileData.precisa_validacao,
            };

            if (!fileData.documento_extra) {
              const index = `group_${fileData.tipo_arquivo.grupo}`;
              if (index === 'group_1') {
                if (fileData.vendedor) {
                  const sallerFound = sallersData.find(
                    saller => saller === `V${fileData.vendedor}`,
                  );
                  if (!sallerFound) {
                    sallersData.push(`V${fileData.vendedor}`);
                  }
                  if (analisisProccessData[index]) {
                    if (
                      analisisProccessData[index][
                        [fileData.tipo_arquivo.tipo_arquivo]
                      ]
                    ) {
                      analisisProccessData[index][
                        [fileData.tipo_arquivo.tipo_arquivo]
                      ].push(file);
                    } else {
                      analisisProccessData[index][
                        fileData.tipo_arquivo.tipo_arquivo
                      ] = [file];
                    }
                  } else {
                    analisisProccessData[index] = [];
                    analisisProccessData[index][
                      fileData.tipo_arquivo.tipo_arquivo
                    ] = [file];
                  }
                }
              } else if (analisisProccessData[index]) {
                if (
                  analisisProccessData[index][
                    [fileData.tipo_arquivo.tipo_arquivo]
                  ]
                ) {
                  analisisProccessData[index][
                    [fileData.tipo_arquivo.tipo_arquivo]
                  ].push(file);
                } else {
                  analisisProccessData[index][
                    fileData.tipo_arquivo.tipo_arquivo
                  ] = [file];
                }
              } else {
                analisisProccessData[index] = [];
                analisisProccessData[index][
                  fileData.tipo_arquivo.tipo_arquivo
                ] = [file];
              }
            } else {
              const index = `group_${fileData.tipo_arquivo.grupo}`;
              if (index === 'group_1') {
                if (fileData.vendedor) {
                  const sallerFound = sallersData.find(
                    saller => saller === `V${fileData.vendedor}`,
                  );
                  if (!sallerFound) {
                    sallersData.push(`V${fileData.vendedor}`);
                  }
                  if (analisisExtraData[index]) {
                    if (
                      analisisExtraData[index][
                        [fileData.tipo_arquivo.tipo_arquivo]
                      ]
                    ) {
                      analisisExtraData[index][
                        [fileData.tipo_arquivo.tipo_arquivo]
                      ].push(file);
                    } else {
                      analisisExtraData[index][
                        fileData.tipo_arquivo.tipo_arquivo
                      ] = [file];
                    }
                  } else {
                    analisisExtraData[index] = [];
                    analisisExtraData[index][
                      fileData.tipo_arquivo.tipo_arquivo
                    ] = [file];
                  }
                }
              } else if (analisisExtraData[index]) {
                if (
                  analisisExtraData[index][[fileData.tipo_arquivo.tipo_arquivo]]
                ) {
                  analisisExtraData[index][
                    [fileData.tipo_arquivo.tipo_arquivo]
                  ].push(file);
                } else {
                  analisisExtraData[index][fileData.tipo_arquivo.tipo_arquivo] =
                    [file];
                }
              } else {
                analisisExtraData[index] = [];
                analisisExtraData[index][fileData.tipo_arquivo.tipo_arquivo] = [
                  file,
                ];
              }
            }
          });

          Object.keys(analisisProccessData).forEach(groupKey => {
            Object.keys(analisisProccessData[groupKey]).forEach(typeKey => {
              const documentsData = {
                data: analisisProccessData[groupKey][typeKey],
                statusColor: '#00000000',
              };
              let files = analisisProccessData[groupKey][typeKey].filter(
                file => file.result === 'Reprovado',
              );
              if (files.length === 0) {
                files = analisisProccessData[groupKey][typeKey].filter(
                  file => file.result === 'Aprovado',
                );
                if (files.length > 0) {
                  if (
                    files.length ===
                    analisisProccessData[groupKey][typeKey].length
                  ) {
                    documentsData.statusColor = '#A1C64D';
                  } else {
                    documentsData.statusColor = '#FFC700';
                  }
                }
              } else {
                documentsData.statusColor = '#EB0808';
              }
              analisisProccessData[groupKey][typeKey] = documentsData;
            });
          });

          Object.keys(analisisExtraData).forEach(groupKey => {
            Object.keys(analisisExtraData[groupKey]).forEach(typeKey => {
              const documentsData = {
                data: analisisExtraData[groupKey][typeKey],
                statusColor: '#00000000',
              };

              analisisExtraData[groupKey][typeKey] = documentsData;
            });
          });
        }

        const responseOs = await api.get(`correspondents/oss/${params.slug}`);

        if (
          responseOs.data.status_id === 4 ||
          responseOs.data.status_id === 10 ||
          responseOs.data.status_id === 15 ||
          responseOs.data.status_id === 19
        ) {
          setShowDeleteButton(true);
        }

        const sallersDataAux = sallersData.map(saller => ({
          id: parseInt(saller.substring(1), 10),
          name: saller.toUpperCase(),
          color: randomColor(),
        }));

        if (step !== 2) {
          setParticipantSelected(participantsData[0]);
          setAnalisisDocumentsGroup(analisisData);
          const documentsParticipantsData = [];
          documentsParticipantsData[
            `${participantsData[0].type}_${participantsData[0].id}`
          ] = analisisData;

          const participantsPromise = new Promise(resolve => {
            participantsData.forEach(async (participant, idx) => {
              if (idx !== 0) {
                const responseDocuments = await api.get(
                  `correspondents/oss/files/${params.slug}/${participant.type}/${participant.id}`,
                );

                const sortedDataParticipants =
                  responseDocuments.data.sort(compare);

                const data = [];
                sortedDataParticipants.forEach(fileData => {
                  let type = 'others';
                  if (fileData.mimeType) {
                    if (fileData.mimeType.match('pdf')) {
                      type = 'pdf';
                    } else if (fileData.mimeType.match('image')) {
                      type = 'image';
                    }
                  }

                  const file = {
                    id: fileData.id,
                    name: fileData.nome,
                    observation: fileData.obsCorresp,
                    observationUser: fileData.obsCliente,
                    url: fileData.file_url,
                    type,
                    result: fileData.resultado
                      ? fileData.resultado.resultado
                      : '',
                    downloadable:
                      fileData.documento_extra || !fileData.precisa_validacao,
                    vendedor: fileData.vendedor,
                  };

                  const index = `group_${fileData.tipo_arquivo.grupo}`;
                  if (index === 'group_1') {
                    if (data[index]) {
                      if (data[index][[fileData.tipo_arquivo.tipo_arquivo]]) {
                        data[index][[fileData.tipo_arquivo.tipo_arquivo]].push(
                          file,
                        );
                      } else {
                        data[index][fileData.tipo_arquivo.tipo_arquivo] = [
                          file,
                        ];
                      }
                    } else {
                      data[index] = [];
                      data[index][fileData.tipo_arquivo.tipo_arquivo] = [file];
                    }
                  }
                });

                Object.keys(data).forEach(groupKey => {
                  Object.keys(data[groupKey]).forEach(typeKey => {
                    const documentsData = {
                      data: data[groupKey][typeKey],
                      statusColor: '#00000000',
                    };
                    let files = data[groupKey][typeKey].filter(
                      file => file.result === 'Reprovado',
                    );
                    if (files.length === 0) {
                      files = data[groupKey][typeKey].filter(
                        file => file.result === '',
                      );

                      if (files.length === 0) {
                        files = data[groupKey][typeKey].filter(
                          file => file.result === 'Aprovado',
                        );
                      }

                      if (files.length > 0) {
                        const hasEmpty = files.find(file => file.result === '');
                        if (!hasEmpty) {
                          if (files.length === data[groupKey][typeKey].length) {
                            documentsData.statusColor = '#A1C64D';
                          } else {
                            documentsData.statusColor = '#FFC700';
                          }
                        }
                      }
                    } else {
                      documentsData.statusColor = '#EB0808';
                    }
                    data[groupKey][typeKey] = documentsData;
                  });
                });

                documentsParticipantsData[
                  `${participant.type}_${participant.id}`
                ] = data;
              }
              if (participantsData.length === idx + 1) {
                resolve();
              }
            });
          });

          await participantsPromise;

          setDocumentsParticipants(documentsParticipantsData);
        }

        if (step !== 1) {
          setSallerSelected(sallersDataAux[0]);
          setAnalisisProccessDocumentsGroup(analisisProccessData);
          setAnalisisExtrasDocumentsGroup(analisisExtraData);
        }

        setClientData(responseOs.data.cliente);
        setParticipants(participantsData);
        setSallers(sallersDataAux);
      })
      .catch(error => {
        validError(error);
      })
      .finally(() => setLoading(false));
  }, [compare, params.page, params.slug, step, validError]);

  const RadioOptions = useMemo(
    () => [
      {
        label: 'Documento Aprovado',
        id: 'approved',
        value: 'yes',
        selected: fileSelected.result === 'Aprovado',
      },
      {
        label: 'Documento reprovado',
        id: 'disapproved',
        value: 'no',
        selected: fileSelected.result === 'Reprovado',
      },
    ],
    [fileSelected],
  );

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleShowSub = useCallback(() => {
    setShowSub(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowSub(false);
  }, []);

  const handleClick = useCallback(
    (documentsGroupSelected, typeKey) => {
      setTypeSelected(typeKey);
      setDocuments(documentsGroupSelected);
      handleShow();
    },
    [handleShow],
  );

  const handleClickSub = useCallback(
    file => {
      api
        .get(`correspondents/oss/files/get/${file.id}`, {
          responseType: 'blob',
        })
        .then(response => {
          const fileData = new File([response.data], file.name, {
            type: response.data.type,
          });
          const urlData = window.URL.createObjectURL(fileData);

          setUrl(urlData);
        })
        .catch(error => {
          validError(error);
        })
        .finally(() => {
          setFileSelected(file);
          setObservation(file.observation);
          handleShowSub();
        });
    },
    [handleShowSub, validError],
  );

  const handleChangeObservation = useCallback(e => {
    setObservation(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          approved: Yup.string().required('Selecione uma das opções acima'),
          observation: Yup.string().when('$observationRequired', {
            is: observationRequired => observationRequired,
            then: Yup.string().required(
              'A observação é obrigatória quando o documento é reprovado.',
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            observationRequired: data.approved === 'no',
          },
        });

        const formData = {
          approved: data.approved === 'yes' ? 1 : 2,
          observation: data.observation,
        };

        const response = await api.patch(
          `correspondents/files/${fileSelected.id}`,
          formData,
        );

        if (response.data) {
          if (step !== 2) {
            const analisysPromise = new Promise(resolve => {
              if (Object.keys(analisisDocumentsGroup).length > 0) {
                Object.keys(analisisDocumentsGroup).forEach(
                  (groupKey, index) => {
                    Object.keys(analisisDocumentsGroup[groupKey]).forEach(
                      typeKey => {
                        const documentFoundIndex = analisisDocumentsGroup[
                          groupKey
                        ][typeKey].data.findIndex(
                          document => document.id === fileSelected.id,
                        );

                        if (documentFoundIndex >= 0) {
                          if (data.approved === 'yes') {
                            fileSelected.result = 'Aprovado';
                          } else if (data.approved === 'no') {
                            fileSelected.result = 'Reprovado';
                          }

                          fileSelected.observation = data.observation;

                          analisisDocumentsGroup[groupKey][typeKey].data[
                            documentFoundIndex
                          ] = fileSelected;

                          let files = analisisDocumentsGroup[groupKey][
                            typeKey
                          ].data.filter(file => file.result === 'Reprovado');

                          if (files.length === 0) {
                            files = analisisDocumentsGroup[groupKey][
                              typeKey
                            ].data.filter(file => file.result === 'Aprovado');
                            if (files.length > 0) {
                              if (
                                files.length ===
                                analisisDocumentsGroup[groupKey][typeKey].data
                                  .length
                              ) {
                                analisisDocumentsGroup[groupKey][
                                  typeKey
                                ].statusColor = '#A1C64D';
                              } else {
                                analisisDocumentsGroup[groupKey][
                                  typeKey
                                ].statusColor = '#FFC700';
                              }
                            }
                          } else {
                            analisisDocumentsGroup[groupKey][
                              typeKey
                            ].statusColor = '#EB0808';
                          }

                          setDocuments(
                            analisisDocumentsGroup[groupKey][typeKey].data,
                          );
                          setAnalisisDocumentsGroup(analisisDocumentsGroup);
                        }
                      },
                    );

                    if (
                      Object.keys(analisisDocumentsGroup).length ===
                      index + 1
                    ) {
                      resolve();
                    }
                  },
                );
              } else {
                resolve();
              }
            });

            await analisysPromise;
          }

          if (step !== 1) {
            const analisysPromise = new Promise(resolve => {
              if (Object.keys(analisisProccessDocumentsGroup).length > 0) {
                Object.keys(analisisProccessDocumentsGroup).forEach(
                  (groupKey, index) => {
                    Object.keys(
                      analisisProccessDocumentsGroup[groupKey],
                    ).forEach(typeKey => {
                      const documentFoundIndex = analisisProccessDocumentsGroup[
                        groupKey
                      ][typeKey].data.findIndex(
                        document => document.id === fileSelected.id,
                      );

                      if (documentFoundIndex >= 0) {
                        if (data.approved === 'yes') {
                          fileSelected.result = 'Aprovado';
                        } else if (data.approved === 'no') {
                          fileSelected.result = 'Reprovado';
                        }

                        fileSelected.observation = data.observation;

                        analisisProccessDocumentsGroup[groupKey][typeKey].data[
                          documentFoundIndex
                        ] = fileSelected;

                        let files = analisisProccessDocumentsGroup[groupKey][
                          typeKey
                        ].data.filter(file => file.result === 'Reprovado');

                        if (files.length === 0) {
                          files = analisisProccessDocumentsGroup[groupKey][
                            typeKey
                          ].data.filter(file => file.result === 'Aprovado');
                          if (files.length > 0) {
                            if (
                              files.length ===
                              analisisProccessDocumentsGroup[groupKey][typeKey]
                                .data.length
                            ) {
                              analisisProccessDocumentsGroup[groupKey][
                                typeKey
                              ].statusColor = '#A1C64D';
                            } else {
                              analisisProccessDocumentsGroup[groupKey][
                                typeKey
                              ].statusColor = '#FFC700';
                            }
                          }
                        } else {
                          analisisProccessDocumentsGroup[groupKey][
                            typeKey
                          ].statusColor = '#EB0808';
                        }

                        setDocuments(
                          analisisProccessDocumentsGroup[groupKey][typeKey]
                            .data,
                        );
                        setAnalisisProccessDocumentsGroup(
                          analisisProccessDocumentsGroup,
                        );
                      }
                    });

                    if (
                      Object.keys(analisisProccessDocumentsGroup).length ===
                      index + 1
                    ) {
                      resolve();
                    }
                  },
                );
              } else {
                resolve();
              }
            });

            await analisysPromise;
          }

          handleClose();
          handleShow();
          reset();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          validError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      analisisDocumentsGroup,
      analisisProccessDocumentsGroup,
      fileSelected,
      handleClose,
      handleShow,
      step,
      validError,
    ],
  );

  const handleDelete = useCallback(
    document => {
      Swal.fire({
        icon: 'warning',
        title: 'Deletar arquivo',
        text: `Deseja deletar o arquivo ${document.name}`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#4DC68C',
        cancelButtonColor: '#F91818',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await api.delete(
            `correspondents/files/${document.id}`,
          );
          if (response.data) {
            if (step !== 2) {
              Object.keys(analisisDocumentsGroup).forEach(groupKey => {
                Object.keys(analisisDocumentsGroup[groupKey]).forEach(
                  typeKey => {
                    const newData = analisisDocumentsGroup[groupKey][
                      typeKey
                    ].data.filter(
                      documentData => documentData.id !== document.id,
                    );
                    analisisDocumentsGroup[groupKey][typeKey].data = newData;
                  },
                );
              });

              Object.keys(analisisDocumentsGroup).forEach(groupKey => {
                Object.keys(analisisDocumentsGroup[groupKey]).forEach(
                  typeKey => {
                    const documentsData = {
                      data: analisisDocumentsGroup[groupKey][typeKey],
                      statusColor: '#00000000',
                    };
                    let files = analisisDocumentsGroup[groupKey][
                      typeKey
                    ].data.filter(file => file.result === 'Reprovado');
                    if (files.length === 0) {
                      files = analisisDocumentsGroup[groupKey][
                        typeKey
                      ].data.filter(file => file.result === 'Aprovado');
                      if (files.length > 0) {
                        if (
                          files.length ===
                          analisisDocumentsGroup[groupKey][typeKey].data.length
                        ) {
                          documentsData.statusColor = '#A1C64D';
                        } else {
                          documentsData.statusColor = '#FFC700';
                        }
                      }
                    } else {
                      documentsData.statusColor = '#EB0808';
                    }
                    analisisDocumentsGroup[groupKey][typeKey] = documentsData;
                  },
                );
              });

              const newDocuments = documents.filter(
                documentData => documentData.id !== document.id,
              );

              setDocuments(newDocuments);
              setAnalisisDocumentsGroup(analisisDocumentsGroup);
            }

            if (step !== 1) {
              Object.keys(analisisProccessDocumentsGroup).forEach(groupKey => {
                Object.keys(analisisProccessDocumentsGroup[groupKey]).forEach(
                  typeKey => {
                    const newData = analisisProccessDocumentsGroup[groupKey][
                      typeKey
                    ].data.filter(
                      documentData => documentData.id !== document.id,
                    );
                    analisisProccessDocumentsGroup[groupKey][typeKey].data =
                      newData;
                  },
                );
              });

              Object.keys(analisisProccessDocumentsGroup).forEach(groupKey => {
                Object.keys(analisisProccessDocumentsGroup[groupKey]).forEach(
                  typeKey => {
                    const documentsData = {
                      data: analisisProccessDocumentsGroup[groupKey][typeKey],
                      statusColor: '#00000000',
                    };
                    let files = analisisProccessDocumentsGroup[groupKey][
                      typeKey
                    ].data.filter(file => file.result === 'Reprovado');
                    if (files.length === 0) {
                      files = analisisProccessDocumentsGroup[groupKey][
                        typeKey
                      ].data.filter(file => file.result === 'Aprovado');
                      if (files.length > 0) {
                        if (
                          files.length ===
                          analisisProccessDocumentsGroup[groupKey][typeKey].data
                            .length
                        ) {
                          documentsData.statusColor = '#A1C64D';
                        } else {
                          documentsData.statusColor = '#FFC700';
                        }
                      }
                    } else {
                      documentsData.statusColor = '#EB0808';
                    }
                    analisisProccessDocumentsGroup[groupKey][typeKey] =
                      documentsData;
                  },
                );
              });

              const newDocuments = documents.filter(
                documentData => documentData.id !== document.id,
              );

              setDocuments(newDocuments);
              setAnalisisProccessDocumentsGroup(analisisProccessDocumentsGroup);
            }
          }
        }
      });
    },
    [analisisDocumentsGroup, analisisProccessDocumentsGroup, documents, step],
  );

  const handleSelectParticipant = useCallback(
    async participant => {
      setLoading(true);
      try {
        if (step !== 2) {
          const response = await api.get(
            `correspondents/oss/files/${params.slug}/${participant.type}/${participant.id}`,
          );

          const sortedData = response.data.sort(compare);

          const data = [];
          sortedData.forEach(fileData => {
            let type = 'others';
            if (fileData.mimeType) {
              if (fileData.mimeType.match('pdf')) {
                type = 'pdf';
              } else if (fileData.mimeType.match('image')) {
                type = 'image';
              }
            }

            const file = {
              id: fileData.id,
              name: fileData.nome,
              observation: fileData.obsCorresp,
              observationUser: fileData.obsCliente,
              url: fileData.file_url,
              type,
              result: fileData.resultado ? fileData.resultado.resultado : '',
              downloadable:
                fileData.documento_extra || !fileData.precisa_validacao,
              vendedor: fileData.vendedor,
            };

            const index = `group_${fileData.tipo_arquivo.grupo}`;
            if (index === 'group_1') {
              if (data[index]) {
                if (data[index][[fileData.tipo_arquivo.tipo_arquivo]]) {
                  data[index][[fileData.tipo_arquivo.tipo_arquivo]].push(file);
                } else {
                  data[index][fileData.tipo_arquivo.tipo_arquivo] = [file];
                }
              } else {
                data[index] = [];
                data[index][fileData.tipo_arquivo.tipo_arquivo] = [file];
              }
            }
          });

          Object.keys(data).forEach(groupKey => {
            Object.keys(data[groupKey]).forEach(typeKey => {
              const documentsData = {
                data: data[groupKey][typeKey],
                statusColor: '#00000000',
              };
              let files = data[groupKey][typeKey].filter(
                file => file.result === 'Reprovado',
              );
              if (files.length === 0) {
                files = data[groupKey][typeKey].filter(
                  file => file.result === '',
                );

                if (files.length === 0) {
                  files = data[groupKey][typeKey].filter(
                    file => file.result === 'Aprovado',
                  );
                }

                if (files.length > 0) {
                  const hasEmpty = files.find(file => file.result === '');
                  if (!hasEmpty) {
                    if (files.length === data[groupKey][typeKey].length) {
                      documentsData.statusColor = '#A1C64D';
                    } else {
                      documentsData.statusColor = '#FFC700';
                    }
                  }
                }
              } else {
                documentsData.statusColor = '#EB0808';
              }

              data[groupKey][typeKey] = documentsData;
            });
          });

          const newDocumentsParticipants = documentsParticipants;

          newDocumentsParticipants[`${participant.type}_${participant.id}`] =
            data;

          setDocumentsParticipants(newDocumentsParticipants);
          setParticipantSelected(participant);
          setAnalisisDocumentsGroup(data);
        }
      } catch (error) {
        validError(error);
      } finally {
        setLoading(false);
      }
    },
    [compare, documentsParticipants, params.slug, step, validError],
  );

  const handleSubmitAnalisis = useCallback(() => {
    Swal.fire({
      title: 'Confirma o envio da análise?',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#4DC68C',
      cancelButtonColor: '#F91818',
      cancelButtonText: 'Não',
      reverseButtons: true,
      icon: 'info',
    })
      .then(async result => {
        setLoading(true);
        if (result.isConfirmed) {
          const response = await api.get(`correspondents/oss/${params.slug}`);
          const results = [];
          if (step !== 2) {
            const analisisDocumentsPromise = new Promise(resolve => {
              if (Object.keys(analisisDocumentsGroup).length > 0) {
                Object.keys(analisisDocumentsGroup).forEach(
                  (groupKey, index) => {
                    if (step >= index + 1) {
                      Object.keys(analisisDocumentsGroup[groupKey]).forEach(
                        typeKey => {
                          if (
                            analisisDocumentsGroup[groupKey][typeKey]
                              .statusColor === '#EB0808'
                          ) {
                            results.push('disapproved');
                          } else if (
                            analisisDocumentsGroup[groupKey][typeKey]
                              .statusColor === '#A1C64D'
                          ) {
                            results.push('Aprovado');
                          } else {
                            results.push('');
                          }
                        },
                      );
                    }

                    if (
                      Object.keys(analisisDocumentsGroup).length ===
                      index + 1
                    ) {
                      resolve();
                    }
                  },
                );
              } else {
                resolve();
              }
            });
            await analisisDocumentsPromise;

            const documentsParticipantsPromise = new Promise(resolve => {
              if (Object.keys(documentsParticipants).length > 0) {
                Object.keys(documentsParticipants).forEach(
                  (participantIndex, idx) => {
                    if (
                      participantIndex !==
                      `${participantSelected.type}_${participantSelected.id}`
                    ) {
                      Object.keys(
                        documentsParticipants[participantIndex],
                      ).forEach((groupKey, index) => {
                        if (step >= index + 1) {
                          Object.keys(
                            documentsParticipants[participantIndex][groupKey],
                          ).forEach(typeKey => {
                            if (
                              documentsParticipants[participantIndex][groupKey][
                                typeKey
                              ].statusColor === '#EB0808'
                            ) {
                              results.push('disapproved');
                            } else if (
                              documentsParticipants[participantIndex][groupKey][
                                typeKey
                              ].statusColor === '#A1C64D'
                            ) {
                              results.push('Aprovado');
                            } else {
                              results.push('');
                            }
                          });
                        }
                      });
                    }

                    if (Object.keys(documentsParticipants).length === idx + 1) {
                      resolve();
                    }
                  },
                );
              } else {
                resolve();
              }
            });
            await documentsParticipantsPromise;
          }

          if (step !== 1) {
            const analisisProccessDocumentsPromise = new Promise(resolve => {
              if (Object.keys(analisisProccessDocumentsGroup).length > 0) {
                Object.keys(analisisProccessDocumentsGroup).forEach(
                  (groupKey, index) => {
                    if (step >= index + 1) {
                      Object.keys(
                        analisisProccessDocumentsGroup[groupKey],
                      ).forEach(typeKey => {
                        if (
                          analisisProccessDocumentsGroup[groupKey][typeKey]
                            .statusColor === '#EB0808'
                        ) {
                          results.push('disapproved');
                        } else if (
                          analisisProccessDocumentsGroup[groupKey][typeKey]
                            .statusColor === '#A1C64D'
                        ) {
                          results.push('Aprovado');
                        } else {
                          results.push('');
                        }
                      });
                    }

                    if (
                      Object.keys(analisisProccessDocumentsGroup).length ===
                      index + 1
                    ) {
                      resolve();
                    }
                  },
                );
              } else {
                resolve();
              }
            });

            await analisisProccessDocumentsPromise;
          }

          const disapproved = results.find(
            resultData => resultData === 'disapproved',
          );

          const notValidated = results.find(resultData => resultData === '');

          if (typeof notValidated !== 'undefined') {
            Swal.fire({
              title: 'Algumas análises estão pendentes',
              text: 'Antes de enviar, revise todos os arquivos de todos os envolvidos.',
              showConfirmButton: false,
              showCloseButton: true,
              icon: 'warning',
            });
          } else {
            if (
              params.page === 'verificar-documentos' &&
              response.data.status.id <= 6
            ) {
              if (disapproved) {
                const formData = {
                  status_id: 5,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              } else if (response.data.status.id !== 6) {
                const formData = {
                  status_id: 6,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              }
            }

            if (
              params.page === 'analise-de-abertura-de-processo' &&
              response.data.status.id <= 12
            ) {
              if (disapproved) {
                const formData = {
                  status_id: 11,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              } else if (response.data.status.id !== 12) {
                const formData = {
                  status_id: 12,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              }
            }

            if (
              params.page === 'resultado-da-conformidade' &&
              response.data.status.id <= 17
            ) {
              if (disapproved) {
                const formData = {
                  status_id: 16,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              } else if (response.data.status.id !== 17) {
                const formData = {
                  status_id: 17,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              }
            }

            if (
              params.page === 'fichas-cadastrais' &&
              response.data.status.id <= 21
            ) {
              if (disapproved) {
                const formData = {
                  status_id: 20,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              } else if (response.data.status.id !== 21) {
                const formData = {
                  status_id: 21,
                };
                await api.put(`correspondents/oss/${params.slug}`, formData);
              }
            }

            history.push(
              `${process.env.PUBLIC_URL}/ordem-de-servico/${params.slug}`,
            );
          }
        }
      })
      .catch(error => {
        validError(error);
      })
      .finally(() => setLoading(false));
  }, [
    analisisDocumentsGroup,
    analisisProccessDocumentsGroup,
    documentsParticipants,
    history,
    params.page,
    params.slug,
    participantSelected.id,
    participantSelected.type,
    step,
    validError,
  ]);

  const handleSelectSaller = useCallback(saller => {
    setSallerSelected(saller);
  }, []);

  const handleClickDownload = useCallback(() => {
    setLoading(true);
    api
      .get(`correspondents/files/download-all/${params.slug}`, {
        responseType: 'blob',
      })
      .then(response => {
        const urlData = window.URL.createObjectURL(new Blob([response.data]), {
          type: 'application/zip',
        });

        const link = document.createElement('a');
        link.href = urlData;
        link.download = `${clientData.nome.toUpperCase()}.zip`;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(urlData);
          link.remove();
        }, 100);
      })
      .catch(error => validError(error))
      .finally(() => setLoading(false));
  }, [clientData.nome, params.slug, validError]);

  return (
    <Container className="flex items-start">
      <div className="w-full h-full">
        <div className="flex flex-row justify-end h-full">
          <Background className="hidden md:block w-3/12 p-8">
            <img src={logo} alt="Logo branca" />
          </Background>
          <AnimationContainer className="w-full md:w-9/12 px-2 md:p-8">
            <div className="flex flex-wrap justify-between items-start w-full">
              <div className="w-full md:w-10/12 flex items-start order-1 md:order-0">
                <Link
                  to={`${process.env.PUBLIC_URL}/ordem-de-servico/${params.slug}`}
                  className="border-0 bg-transparent mr-3"
                >
                  <IoIosArrowBack size={25} color="#CCCCCC" />
                </Link>
                <div>
                  <h1 className="text-2xl color-onyx font-semibold">
                    Subir Arquivos
                  </h1>
                  <p>
                    Envie seus documentos e dos participantes que irão dividir o
                    financiamento
                  </p>
                </div>
              </div>
              <div className="w-full md:w-2/12 flex md:flex-col md:items-end text-center mb-4 order-0 md:order-1 p-4 md:px-0">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            {step !== 2 && (
              <>
                <div className="flex items-center participants pt-6 mb-3 px-10">
                  <h3 className="text-lg">
                    Documentos do proponente, conjuge e/ou participantes
                  </h3>
                </div>
                <div className="participants mb-3 px-10">
                  {step !== 2 && (
                    <div className="w-full flex items-center">
                      {participants.map((participant, index) => (
                        <Fragment key={`${participant.type}_${participant.id}`}>
                          {index !== 0 && <hr />}
                          <button
                            type="button"
                            className={`bg-transparent ${
                              participantSelected === participant
                                ? 'active'
                                : ''
                            }`}
                            onClick={() => handleSelectParticipant(participant)}
                          >
                            <CircleName
                              name={participant.name}
                              color={participant.color}
                            />
                          </button>
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
                {Object.keys(analisisDocumentsGroup).map((groupKey, index) => (
                  <Fragment key={groupKey}>
                    {groupKey === 'group_2' && (
                      <div
                        className={`flex items-center participants mb-3 px-10 ${
                          step >= index + 1 ? 'block' : 'hidden'
                        }`}
                      >
                        <h3 className="text-lg">
                          Documentos para abertura de processo
                        </h3>
                      </div>
                    )}
                    {groupKey === 'group_3' && (
                      <div
                        className={`flex items-center participants mb-3 px-10 ${
                          step >= index + 1 ? 'block' : 'hidden'
                        }`}
                      >
                        <h3 className="text-lg">
                          Documentos da empresa que está vendendendo o terreno
                        </h3>
                      </div>
                    )}
                    <Step
                      active={step >= index + 1}
                      className="px-2 md:px-8 lg:px-10 w-full md:w-10/12 mb-8"
                    >
                      <div className="flex flex-wrap">
                        {Object.keys(analisisDocumentsGroup[groupKey]).map(
                          typeKey => (
                            <div
                              key={
                                analisisDocumentsGroup[groupKey][typeKey].data
                                  .id
                              }
                              className="w-full lg:w-6/12 my-4 lg:pr-4"
                            >
                              <ButtonAttachment
                                title={typeKey}
                                icon={
                                  typeKey === 'Informações pessoais'
                                    ? 'attachment'
                                    : 'image'
                                }
                                onClick={() =>
                                  handleClick(
                                    analisisDocumentsGroup[groupKey][typeKey]
                                      .data,
                                    typeKey,
                                  )
                                }
                                statusColor={
                                  analisisDocumentsGroup[groupKey][typeKey]
                                    .statusColor
                                }
                                iconColor="#E6E7E8"
                              />
                            </div>
                          ),
                        )}
                      </div>
                    </Step>
                  </Fragment>
                ))}
              </>
            )}

            {step !== 1 && (
              <>
                {sallers.length > 0 && (
                  <>
                    <div className="flex items-center participants pt-6 mb-3  px-10">
                      <h3 className="text-lg">
                        Documentos do(s) vendedor(es) do terreno
                      </h3>
                    </div>
                    <div className="participants mb-3 px-10">
                      <div className="w-full flex items-center">
                        {sallers.map((saller, index) => (
                          <Fragment key={saller.id}>
                            {index !== 0 && <hr />}
                            <button
                              type="button"
                              className={`bg-transparent ${
                                sallerSelected === saller ? 'active' : ''
                              }`}
                              onClick={() => handleSelectSaller(saller)}
                            >
                              <CircleName
                                name={saller.name}
                                color={saller.color}
                              />
                            </button>
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {Object.keys(analisisProccessDocumentsGroup).map(
                  (groupKey, index) => (
                    <Fragment key={groupKey}>
                      {groupKey === 'group_2' && (
                        <div
                          className={`flex items-center participants mb-2 px-10 ${
                            step >= index + 1 ? 'block' : 'hidden'
                          }`}
                        >
                          <h3 className="text-lg">
                            Documentos para abertura de processo
                          </h3>
                        </div>
                      )}
                      {groupKey === 'group_3' && (
                        <div
                          className={`flex items-center participants mb-2 px-10 ${
                            step >= index + 1 ? 'block' : 'hidden'
                          }`}
                        >
                          <h3 className="text-lg">
                            Documentos da empresa que está vendendendo o terreno
                          </h3>
                        </div>
                      )}
                      {groupKey === 'group_6' && (
                        <div className="flex items-center participants mb-2 px-10">
                          <h3 className="text-lg">Laudos</h3>
                        </div>
                      )}
                      {groupKey === 'group_7' && (
                        <div className="flex items-center participants mb-2 px-10">
                          <h3 className="text-lg">Fichas</h3>
                        </div>
                      )}
                      <Step
                        active
                        className="px-2 md:px-8 lg:px-10 w-full md:w-10/12 mb-8"
                      >
                        <div className="flex flex-wrap">
                          {Object.keys(
                            analisisProccessDocumentsGroup[groupKey],
                          ).map(typeKey => (
                            <Fragment
                              key={
                                analisisProccessDocumentsGroup[groupKey][
                                  typeKey
                                ].data.id
                              }
                            >
                              {/* {analisisProccessDocumentsGroup[groupKey][typeKey]
                                .data.vendedor === sallerSelected.id} */}
                              <div className="w-full lg:w-6/12 my-4 lg:pr-4">
                                <ButtonAttachment
                                  title={typeKey}
                                  icon="image"
                                  onClick={() =>
                                    handleClick(
                                      analisisProccessDocumentsGroup[groupKey][
                                        typeKey
                                      ].data,
                                      typeKey,
                                    )
                                  }
                                  statusColor={
                                    analisisProccessDocumentsGroup[groupKey][
                                      typeKey
                                    ].statusColor
                                  }
                                  iconColor="#E6E7E8"
                                />
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </Step>
                    </Fragment>
                  ),
                )}

                {Object.keys(analisisExtrasDocumentsGroup).length > 0 && (
                  <>
                    <div className="flex items-center participants mb-2 px-10">
                      <h3 className="text-lg">Documentos extras</h3>
                    </div>
                    <Step
                      active
                      className="px-2 md:px-8 lg:px-10 w-full md:w-10/12 mb-8"
                    >
                      <div className="flex flex-wrap">
                        {Object.keys(analisisExtrasDocumentsGroup).map(
                          groupKey => (
                            <Fragment key={groupKey}>
                              {Object.keys(
                                analisisExtrasDocumentsGroup[groupKey],
                              ).map(typeKey => (
                                <Fragment
                                  key={
                                    analisisExtrasDocumentsGroup[groupKey][
                                      typeKey
                                    ].data.id
                                  }
                                >
                                  <div className="w-full lg:w-6/12 my-4 lg:pr-4">
                                    <ButtonAttachment
                                      title={typeKey}
                                      icon="image"
                                      onClick={() =>
                                        handleClick(
                                          analisisExtrasDocumentsGroup[
                                            groupKey
                                          ][typeKey].data,
                                          typeKey,
                                        )
                                      }
                                      statusColor={
                                        analisisExtrasDocumentsGroup[groupKey][
                                          typeKey
                                        ].statusColor
                                      }
                                      iconColor="#A1C64D"
                                    />
                                  </div>
                                </Fragment>
                              ))}
                            </Fragment>
                          ),
                        )}
                      </div>
                    </Step>
                  </>
                )}
              </>
            )}
            <div className="flex flex-wrap justify-center lg:justify-between items-center pb-6 mb-8 lg:pl-12 lg:pr-32">
              <div className="w-full md:w-7/12 lg:w-4/12 order-1 lg:order-0">
                <button
                  // href={`${process.env.REACT_APP_API_URL}/correspondents/${params.slug}/files/${user.id}`}
                  type="button"
                  className="border-0 btn-download py-3 px-10 mr-3 w-full lg:w-auto flex justify-center"
                  // target="blank"
                  onClick={handleClickDownload}
                >
                  Baixar tudo{' '}
                  <AiOutlineCloudDownload
                    size={20}
                    color="#ffffff"
                    className="ml-2"
                  />
                </button>
              </div>
              <div className="w-full md:w-7/12 lg:w-8/12 order-0 lg:order-1 flex flex-wrap lg:flex-row mb-5 lg:mb-0 justify-end">
                <Link
                  to={`${process.env.PUBLIC_URL}/ordem-de-servico/${params.slug}`}
                  className="border-0 btn-back py-3 px-10 order-1 lg:order-0 block text-center mr-0 lg:mr-3 w-full lg:w-auto"
                >
                  Dashboard
                </Link>
                <button
                  type="button"
                  onClick={handleSubmitAnalisis}
                  className="border-0 btn-submit py-3 px-10 order-0 lg:order-1 mb-5 lg:mb-0 w-full lg:w-auto"
                >
                  Concluir análise
                </button>
              </div>
            </div>
          </AnimationContainer>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="mb-8">
          {console.log(typeSelected)}
          <h4 className="text-xl color-onyx font-semibold">{typeSelected}</h4>
          <p>Selecione o(s) Documento(s) pedido</p>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-wrap justify-center">
            {documents.map(document => (
              <div className="w-full my-2">
                <SubButtonAttachment
                  title={document.name}
                  icon="image"
                  onClick={() => handleClickSub(document)}
                  onDelete={() => handleDelete(document)}
                  approved={document.result}
                  observation={document.observation}
                  downloadable={document.downloadable}
                  fileUrl={document.url}
                  iconColor={document.downloadable ? '#A1C64D' : '#E6E7E8'}
                  showDeleteButton={
                    showDeleteButton && document.result !== 'Aprovado'
                  }
                />
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showSub} onHide={handleClose}>
        {Object.keys(fileSelected).length > 0 && (
          <>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Modal.Header className="mb-8">
                <h4 className="text-xl color-onyx font-semibold mb-6 lg:mb-0">
                  {fileSelected.name}
                </h4>
                <div className="md:flex flex-wrap justify-center lg:justify-start mt-2">
                  <InputRadio name="approved" options={RadioOptions} />
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="flex flex-wrap justify-center items-center">
                  <div className="w-full md:w-8/12 lg:w-5/12 my-4 lg:pr-4">
                    <p>Observação: {fileSelected.observationUser}</p>
                    <div className="w-full lg:my-16">
                      <label
                        htmlFor="observation"
                        className="text-lg mb-2 block"
                      >
                        Caso queira, deixe alguma observação:
                      </label>
                      <Box className="flex items-center justify-between p-2 h-full">
                        <Input
                          id="observation"
                          name="observation"
                          className="border-none"
                          onChange={handleChangeObservation}
                          value={observation}
                        />
                      </Box>
                    </div>
                    <button
                      type="submit"
                      className="hidden lg:block w-full py-2 rounded-full"
                    >
                      Salvar
                    </button>
                  </div>
                  <div className="w-full md:w-8/12 lg:w-7/12 my-4 lg:pl-4">
                    <div className="box">
                      <div className="flex justify-end bg-gray p-3">
                        <a
                          href={url}
                          className="btn-download py-2 px-4 rounded-full"
                          target="blank"
                          download={fileSelected.name}
                        >
                          Baixar
                        </a>
                      </div>
                      <div className="p-10 document-preview flex justify-center items-center">
                        {fileSelected.type === 'image' && (
                          <img
                            src={url}
                            alt={fileSelected.name}
                            className="w-full"
                          />
                        )}
                        {fileSelected.type === 'pdf' && (
                          <iframe
                            src={url}
                            title={fileSelected.name}
                            allow
                            allowFullScreen
                            allowTransparency
                            className="w-full"
                          />
                        )}
                        {fileSelected.type === 'others' && (
                          <p className="text-center">Formato não suportado</p>
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="lg:hidden mt-5 w-full py-2 rounded-full"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Form>
          </>
        )}
      </Modal>
      <Loading active={loading} />
    </Container>
  );
}
