import styled from 'styled-components';

export const Container = styled.div`
  transition-duration: 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  padding: 50px 0;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
`;

export const Content = styled.div`
  background: #ffffff;
  box-shadow: -17px 17px 34px rgba(102, 102, 102, 0.2),
    17px 17px 43px rgba(102, 102, 102, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(102, 102, 102, 0.5);
  border-radius: 16px;

  min-width: 300px;
  max-width: 1000px;
  min-height: 350px;
`;

export const HeaderContent = styled.div``;

export const BodyContent = styled.div``;

export const FooterContent = styled.div``;
