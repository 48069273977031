import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-bottom: 1.5px solid #e6e7e8;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    position: relative;

    .button-show {
      position: absolute;
      top: -6px;
      right: -10px;
      width: 60px;
      text-align: center;
      background: none;
      border: 0;
      border-left: 1px solid rgba(224, 224, 224, 0.8);
      padding: 6px 10px 5px;
      color: #8c8c8c;
    }
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #8c8c8c;
    transition-duration: 0.2s;

    ::placeholder {
      color: #666360;
    }
  }

  .options {
    border-radius: 0 0 10px 10px;
    background-color: #f2f5f7;
    border: 1.5px solid #dddddd;
    width: calc(100% + 2px);
    left: -1px;
    top: 34px;
    max-height: 200px;
    overflow: auto;
    z-index: 100;
    position: absolute;

    button,
    span {
      border: none;
      border-bottom: 1px solid #dddddd;
      background: transparent;
      transition-duration: 0.3s;
      color: #9c9c9c;
      font-weight: 700;
      padding: 5px 0;
      text-align: center;
    }

    span {
      display: block;
    }

    button {
      :hover {
        background-color: ${darken(0.02, '#f2f5f7')};
      }
    }
  }

  svg {
    margin-right: 16px;
    color: #666360;
    transition-duration: 0.2s;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFilled &&
    css`
      svg {
        color: #fff;
      }
    `}

  ${props =>
    props.isFocuses &&
    css`
      color: #fff;
      border-color: #fff !important;
      border-radius: 10px 10px 0 0 !important;

      svg {
        color: #fff;
      }
    `}
`;
