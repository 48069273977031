import React from 'react';
import PropTypes from 'prop-types';

import { CartProvider } from './Cart';
import { AuthProvider } from './Auth';
import { ValidErrorProvider } from './ValidError';

export default function AppProvider({ children }) {
  return (
    <AuthProvider>
      <ValidErrorProvider>
        <CartProvider>{children}</CartProvider>
      </ValidErrorProvider>
    </AuthProvider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
