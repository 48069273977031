import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken } from 'polished';
import ReactChatView from 'react-chatview';

export const Container = styled.div`
  bottom: 30px;
  right: 30px;
  z-index: 100;

  > div > button {
    height: 70px;
    width: 70px;
    background: #a1c64d;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.24);
    border-radius: 150px;
    color: #ffffff;
    transition-duration: 0.3s;
  }

  .notification {
    position: relative;
    transition-duration: 0.3s;

    :after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #c64d4d;
      top: 0px;
      right: 5px;
    }
  }
`;

export const ChatForm = styled(Form)`
  .box {
    transition-duration: 0.3s;
    margin: 0 25px ${props => (props.active ? '-10px' : '-50px')} 0;
    width: ${props => (props.active ? '375px' : '0px')};
    height: ${props => (props.active ? '486px' : '0px')};
    box-shadow: 0px 0px 10px;
    border-radius: 20px 20px 0px 20px;
    overflow: hidden;
    background-color: #f6f6f6;
  }

  > div {
    width: 100%;
  }

  #auxText {
    opacity: 0;
    visibility: hidden;
    white-space: nowrap;
  }

  .header,
  .footer {
    background-color: #ffffff;
  }

  .footer {
    textarea {
      color: ${darken(0.3, '#b0b6c3')} !important;
      font-weight: 500;
      resize: none;
      min-height: 25px;
      height: auto;
      max-height: 75px;
      ::placeholder {
        color: #b0b6c3 !important;
      }
    }
  }
`;

export const Content = styled(ReactChatView)`
  background-color: #f6f6f6;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 0;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #d6d6d6;
  }

  > div {
    div {
      border-radius: 10px;
      position: relative;
    }

    small {
      color: #b0b6c3;
      margin-top: 4px;
    }
  }

  .my-message {
    max-width: 200px;
    display: table;

    div {
      background-color: #666666;

      p {
        color: #ffffff;
        margin-bottom: 0;
        text-align: right;
      }
    }

    + .your-message {
      margin-top: 20px;
    }

    + .my-message {
      margin-top: 1px;
    }
  }

  .my-message.last > div:after {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    right: -7px;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #666666 transparent;
  }

  .your-message {
    max-width: 200px;
    display: table;

    div {
      background-color: #ffffff;
      display: table;

      p {
        color: #646464;
        margin-bottom: 0;
        text-align: left;
      }
    }

    + .my-message {
      margin-top: 20px;
    }

    + .your-message {
      margin-top: 1px;
    }
  }

  .your-message.last div:before {
    content: '';
    background: transparent;
    border-radius: 2px;
    position: absolute;
    left: -7px;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 14px 9px;
    border-color: transparent transparent #ffffff transparent;
  }
`;
