import styled, { keyframes } from 'styled-components';
import { Modal as ModalComponent } from '~/components/Modal';

import signInBackgroung from '~/assets/banners/sign-in-background.png';

export const Container = styled.div`
  background-color: #ffffff;
  min-height: 100vh;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Background = styled.div`
  filter: drop-shadow(-8px 0px 14px rgba(0, 0, 0, 0.25));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${signInBackgroung});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: fixed;
  left: 0;

  img {
    filter: brightness(1000%);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  animation: ${appearFromLeft} 1s;

  h2 {
    color: #b8b9ba;
  }

  p {
    color: #b8b9ba;
  }

  .participants {
    hr {
      width: 33px;
    }

    button {
      transition-duration: 0.3s;
      opacity: 0.5;

      :hover {
        opacity: 1;
      }
    }

    button.active {
      opacity: 1;
    }
  }

  .btn-back {
    background: #dadada;
    border-radius: 26px;
    color: #ffffff;
    font-weight: 700;
  }

  .btn-submit {
    background: #414142;
    border-radius: 26px;
    color: #ffffff;
    font-weight: 700;
  }

  .btn-download {
    background: #a1c64d;
    border-radius: 26px;
    color: #ffffff;
    font-weight: 700;
  }
`;

export const ButtonClient = styled.button`
  background: url('${props => props.src}'),
    ${props => (props.active ? '#03c2ff' : '#E6E7E8')};
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  opacity: ${props => (props.active ? 1 : 0.5)};
`;

export const Step = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
`;

export const Modal = styled(ModalComponent)`
  .modalContent {
    width: 90%;
    max-width: unset;
  }

  .box {
    border: 1px solid #cccccc;
    border-radius: 10px;

    .bg-gray {
      background: #c8c8c8;
      border-radius: 10px 10px 0px 0px;

      a.btn-download {
        background: #a1c64d;
        color: #ffffff;
        font-weight: 600;
      }
    }
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  .card-yellow {
    border: 2px solid #ffbe21;
  }

  .card-green {
    border: 2px solid #a1c64d;
  }

  .document-preview {
    min-height: 300px;
  }

  iframe {
    min-height: 450px;
  }

  button[type='submit'] {
    background: #cccccc;
    color: #ffffff;
    font-weight: 700;
  }
`;

export const Box = styled.div`
  border: 1px solid #cccccc;
  border-radius: 10px;
  position: relative;

  span {
    position: absolute;
    bottom: -25px;
  }
`;
