import styled from 'styled-components';
import { Modal as ModalComponent } from '~/components/Modal';

export const Container = styled.div`
  width: calc(100% - 132px);

  .download-record {
    color: #5c5c5d;
    background: #e0e0e0;
    border-radius: 50px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const Pendency = styled.small`
  ::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => (props.type === 'builder' ? '#ffbe21' : '#9ddef2')};
    display: inline-block;
    margin-right: 10px;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

export const PersonalInfos = styled.div`
  h2 {
    color: #333333;
  }

  p {
    color: #8a8b8b;

    span {
      color: #61635e;
      font-weight: 600;
    }
  }
`;

export const Simulation = styled.div`
  h2 {
    color: #333333;
  }

  button {
    background-color: transparent;
  }

  .before-circle {
    ::before {
      content: '';
      width: 11px;
      height: 11px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .circle-color-purple {
    ::before {
      background: #5b4dbe;
    }
  }

  .circle-color-yellow {
    ::before {
      background: #ffbe21;
    }
  }

  .circle-color-orange {
    ::before {
      background: #ff7235;
    }
  }

  .price-before {
    ::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .price-orange-color {
    ::before {
      background-color: #ff9900;
    }
  }

  .price-yellow-color {
    ::before {
      background-color: #e8e371;
    }
  }

  .value {
    color: #61635e;
  }
`;

export const Modal = styled(ModalComponent)`
  .modalContent {
    width: 1130px;
  }

  .box {
    background: #ffffff;
    box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #cccccc;
    border-radius: 10px;

    p {
      color: #999999;
      font-size: 14px;
    }
  }

  button[type='submit'] {
    background: #cccccc;
    color: #ffffff;
    font-weight: 700;
  }
`;

export const ChatOptions = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;

  button.btn-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #079269;
    border: 0;
  }
`;
