import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaRegUserCircle, FaRocketchat } from 'react-icons/fa';
import { IoSend } from 'react-icons/io5';
import { format, parseISO } from 'date-fns';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';
import { useValidError } from '~/hooks/ValidError';
import { ChatArea, ChatText, Container } from './styles';
import Textarea from '~/components/Textarea';
import tailSend from '~/assets/defaults/tail-dark-gray.svg';
import tailGet from '~/assets/defaults/tail-light-gray.svg';

function MessagesArea() {
  const params = useParams();
  const { user } = useAuth();
  const [chat, setChat] = useState({});
  const [chatId, setChatId] = useState(0);
  const [messages, setMessages] = useState([]);
  const { validError } = useValidError();
  const [page, setPage] = useState(1);
  const [connectionEstablished, setConnectionEstablished] = useState(false);
  const [lastPage, setLastPage] = useState(2);
  const [loadingOldMessages, setLoadingOldMessages] = useState(false);

  useEffect(() => {
    const bodyChat = document.querySelector('.bodyChat');
    if (bodyChat) {
      bodyChat.scrollTo({ top: bodyChat.scrollHeight, behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (params.slug) {
      api
        .get(`correspondents/chats/${params.slug}`, {
          params: {
            type: 'correspondent',
          },
        })
        .then(response => {
          const nameParts =
            response.data.ordem_servico.correspondente.name.split(' ');
          setChat({
            id: response.data.id,
            os_id: response.data.os_id,
            name: `${nameParts[0]} ${nameParts[1]}`,
          });

          const dataMessages = response.data.messages.map(message => ({
            id: message._id,
            user_id: message.user_id,
            content: message.content,
            date: format(parseISO(message.created_at), 'dd/MM/yyyy HH:mm'),
          }));

          setMessages(dataMessages);
          setChatId(params.slug);
        });
    }
  }, [params.slug]);

  useEffect(() => {
    if (chatId && !connectionEstablished) {
      const socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/chat`);

      socket.onopen = () => {
        const data = {
          user_id: user.id,
          chat_id: chatId,
        };

        socket.send(JSON.stringify(data));
        setConnectionEstablished(true);
      };

      socket.onmessage = event => {
        const data = JSON.parse(event.data);
        const message = {
          id: data._id,
          user_id: data.user_id,
          date: format(new Date(), 'dd/MM/yyyy HH:mm'),
          content: data.message,
        };

        setMessages(state => [message, ...state]);
      };

      socket.onerror = () => {
        console.log('Não foi possivel conectar no chat');
      };
    }
  }, [chatId, connectionEstablished, user.id]);

  const loadOldMessages = useCallback(async () => {
    if (!loadingOldMessages) {
      setLoadingOldMessages(true);
      try {
        const newPage = page + 1;
        const response = await api.get(
          `correspondents/chats/messages/${chatId}`,
          {
            params: {
              page: newPage,
            },
          },
        );
        setLastPage(response.data.last_page);

        if (response.data && response.data.data.length > 0) {
          const data = response.data.data.map(message => ({
            id: message._id,
            user_id: message.usuario_id,
            content: message.content,
            date: format(parseISO(message.created_at), 'dd/MM/yyyy HH:mm'),
          }));

          setMessages(state => [...state, ...data]);
          setPage(newPage);
        }
      } catch (error) {
        validError(error);
      } finally {
        setLoadingOldMessages(false);
      }
    }
  }, [loadingOldMessages, page, chatId, validError]);

  const handleSubmit = useCallback(
    async data => {
      try {
        const formData = {
          chat_id: chatId,
          user_id: user.id,
          content: data.message,
        };

        api.post('correspondents/chats/messages', formData);

        const message = {
          id: `${new Date().getTime()}`,
          user_id: user.id,
          date: format(new Date(), 'dd/MM/yyyy HH:mm'),
          content: data.message,
        };

        setMessages(state => [message, ...state]);

        const element = document.getElementById('message');
        if (element) {
          element.value = '';
        }
      } catch (error) {
        validError(error);
      }
    },
    [chatId, user.id, validError],
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (e.target.value.length > 0) {
          const data = { message: e.target.value };
          e.target.value = '';

          handleSubmit(data);
        }
      }
    },
    [handleSubmit],
  );

  const handleInfiniteLoad = useCallback(async () => {
    if (page < lastPage) {
      loadOldMessages();
    }
  }, [lastPage, loadOldMessages, page]);

  return (
    <Container className="w-8/12">
      {Object.keys(chat).length > 0 ? (
        <ChatArea onSubmit={handleSubmit}>
          <div className="border-user flex items-center justify-between px-10 py-4">
            <div className="flex items-center">
              <FaRegUserCircle size={24} color="#C1C1C1" />

              <h2 className="ml-8">{chat.name}</h2>
              {/* <div className="ml-3 circle-online rounded-full" /> */}
            </div>
            <div className="os-user">
              OS: {chat.os_id.toString().padStart(4, '0')}
            </div>
          </div>
          <ChatText
            flipped
            scrollLoadThreshold={100}
            onInfiniteLoad={handleInfiniteLoad}
            className="bodyChat"
          >
            {messages.map(message => (
              <Fragment key={message.id}>
                {message.user_id === user.id ? (
                  <div className="w-full flex justify-end mb-5">
                    <div className="">
                      <div className="bg-dark-gray pt-5 pb-2 px-5 mb-2">
                        {message.content}
                        <img
                          src={tailSend}
                          className="tail-dark-gray ml-auto"
                          alt="tail"
                        />
                      </div>
                      <p className="date-message text-right">{message.date}</p>
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex mb-5">
                    <div className="">
                      <div className="bg-light-gray pt-5 pb-2 px-5 mb-2">
                        {message.content}
                        <img
                          src={tailGet}
                          className="tail-light-gray"
                          alt="tail"
                        />
                      </div>
                      <div className="date-message">{message.date}</div>
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </ChatText>
          <div className="flex flex-wrap px-10 mb-8">
            <div className="w-10/12">
              <Textarea
                id="message"
                name="message"
                className="w-full border-none"
                placeholder="Mensagem"
                rows={2}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="w-2/12">
              <button type="submit" className="flex ml-auto px-4 py-3">
                Enviar&nbsp;&nbsp;
                <IoSend size={19} color="#fff" />
              </button>
            </div>
          </div>
        </ChatArea>
      ) : (
        <ChatArea>
          <div className="change-msg">
            <div className="icon">
              <FaRocketchat color="#9C9C9C" size={50} />
            </div>
            <p className="text-5xl text-center">Selecione uma conversa</p>
          </div>
        </ChatArea>
      )}
    </Container>
  );
}

export default MessagesArea;
