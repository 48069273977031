import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

import { formatPrice } from '~/utils/format';

import { Container } from './styles';

import logo from '~/assets/logos/chart-logo.png';

export default function CompositionChart({ dataChart, className }) {
  const labels = useMemo(() => {
    if (dataChart) {
      const labelsData = dataChart.map(data => data.asset);
      return labelsData;
    }

    return [];
  }, [dataChart]);

  const options = useMemo(() => {
    const data = {
      colors: ['#FF7235', '#FFBE21', '#5B4DBE'],
      plotOptions: {
        pie: {
          expandOnClick: false,
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: '85%',
            labels: {
              show: false,
            },
          },
        },
      },
      stroke: {
        show: false,
        width: 5,
        colors: ['#1d2023'],
        lineCap: 'round',
      },
      labels,
      legend: {
        show: false,
        fontSize: '15px',
        labels: {
          colors: '#929292',
        },
        itemMargin: {
          vertical: 5,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: val => {
          return `R$ ${formatPrice(val)}`;
        },
      },
      tooltip: {
        custom: opt => {
          return `${dataChart[opt.seriesIndex].asset}: R$ ${formatPrice(
            dataChart[opt.seriesIndex].percent,
          )}`;
        },
      },
    };

    return data;
  }, [dataChart, labels]);

  const series = useMemo(() => {
    if (dataChart) {
      const seriesData = dataChart.map(data => data.percent);

      return seriesData;
    }
    return [];
  }, [dataChart]);

  return (
    <Container
      className={`${className} flex justify-center items-center relative`}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={200}
      />
      <img src={logo} alt="Logo" className="absolute" />
    </Container>
  );
}

CompositionChart.propTypes = {
  dataChart: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      asset: PropTypes.string.isRequired,
      percent: PropTypes.string.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
};

CompositionChart.defaultProps = {
  className: '',
};
