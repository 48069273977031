import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

import DashboardLayout from '~/pages/_Layouts/Dashboard';
import DefaultLayout from '~/pages/_Layouts/Default';
import AuthLayout from '~/pages/_Layouts/Auth';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isDashboard,
  ...rest
}) {
  const { user } = useAuth();

  const Layout = useMemo(() => {
    if (isPrivate && isDashboard) {
      return DashboardLayout;
    }

    if (isPrivate) {
      return DefaultLayout;
    }

    return AuthLayout;
  }, [isDashboard, isPrivate]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: user ? '/home/com-correspondente' : '/',
              state: location,
            }}
          />
        );
      }}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isDocument: PropTypes.bool,
  isDashboard: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isDocument: false,
  isDashboard: false,
};
